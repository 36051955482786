import React, { useEffect, useState } from "react";
import { Row, Col, Statistic, Skeleton, Segmented } from "antd";
import { Divider } from "antd";
import AnalyticService from "../../../service/AnalyticService";
import {
  ImportOutlined,
  EyeOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";

const EGTStats = () => {
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);
  const [analyticsData, setAnalyticsData] = useState({
    pageView: 0,
    activeUser: 0,
    eg1: 0,
    eg2: 0,
    eg3: 0,
    eg4: 0,
    reg1: 0,
    reg2: 0,
    egvideo: 0,
    watchtime: 0,
  });
  const [range, setRange] = useState("alle");

  const toPercent = (views, clicks) => {
    return views === 0 || clicks === 0
      ? "0%"
      : `${Math.round((clicks / views) * 100)}%`;
  };

  const cacheAnalyticsData = (data, range) => {
    const cacheKey = `egt_analytics_${range || "default"}`;
    const cachedData = {
      data,
      timestamp: Date.now(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
  };

  const getCachedAnalyticsData = (range) => {
    const cacheKey = `egt_analytics_${range || "default"}`;
    const cachedData = localStorage.getItem(cacheKey);
    if (!cachedData) return null;

    const parsedData = JSON.parse(cachedData);
    const cacheDuration = 2 * 60 * 60 * 1000; // 2 hours

    if (Date.now() - parsedData.timestamp < cacheDuration) {
      return parsedData.data;
    }
    return null;
  };

  const cleanupCache = () => {
    const cachePrefix = "egt_analytics_";
    const keys = Object.keys(localStorage);
    const now = Date.now();
    const cacheDuration = 2 * 60 * 60 * 1000;

    keys.forEach((key) => {
      if (key.startsWith(cachePrefix)) {
        const cachedData = JSON.parse(localStorage.getItem(key));
        if (now - cachedData.timestamp > cacheDuration) {
          localStorage.removeItem(key);
        }
      }
    });
  };

  const fetchAnalytic = async () => {
    cleanupCache();
    setLoadingAnalytics(true);
    try {
      const cachedData = getCachedAnalyticsData(range);
      if (cachedData) {
        setAnalyticsData(cachedData);
        setLoadingAnalytics(false);
        return;
      }

      const { data } = await AnalyticService.getEGTAnalytics(range);
      const activeUser = data[1].Click_Eg1.activeUsers;
      const pageView = data[9].page_view.eventCount;

      const newAnalyticsData = {
        pageView: pageView,
        activeUser: activeUser,
        eg1: toPercent(pageView, data[1].Click_Eg1.eventCount),
        eg2: toPercent(pageView, data[2].Click_Eg2.eventCount),
        eg3: toPercent(pageView, data[3].Click_Eg3.eventCount),
        eg4: toPercent(pageView, data[6].Click_Eg4.eventCount),
        reg1: toPercent(pageView, data[4].Click_Reg1.eventCount),
        reg2: toPercent(pageView, data[5].Click_Reg2.eventCount),
        egvideo: data[7].Play.eventCount,
        watchtime: data[8].Watchtime.eventCount,
      };

      setAnalyticsData(newAnalyticsData);
      cacheAnalyticsData(newAnalyticsData, range);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    } finally {
      setLoadingAnalytics(false);
    }
  };

  useEffect(() => {
    fetchAnalytic();
  }, [range]);

  const stats = [
    { title: "Aktive Nutzer", value: analyticsData.activeUser },
    { title: "Aufrufe Gesamt", value: analyticsData.pageView },
    { title: "Kostenlos testen", value: analyticsData.eg1 },
    { title: "Click Erstgespräch 2", value: analyticsData.eg2 },
    { title: "Click Erstgespräch 3", value: analyticsData.eg3 },
    { title: "Click Erstgespräch 4", value: analyticsData.eg4 },
    { title: "Click jetzt mitmachen", value: analyticsData.reg1 },
    { title: "Click jetzt Kunden gewinnen", value: analyticsData.reg2 },
    { title: "Video Aufrufe", value: analyticsData.egvideo },
    {
      title: "Video Watchtime",
      value: analyticsData.egvideo
        ? `${Math.round(analyticsData.watchtime / analyticsData.egvideo)}%`
        : "0%",
    },
  ];

  return (
    <div>
      <Segmented
        options={["Heute", "letzte 7 Tage", "letzte 30 Tage", "alle"]}
        value={range}
        default="alle"
        onChange={(value) => {
          setRange(value);
        }}
      />
      <Row
        gutter={[64, 22]}
        className="bg-[#FAFAFA] p-12 !ml-0 w-full lg:w-full"
      >
        <div className="w-full flex justify-center mb-4">
          <EyeOutlined className="text-blue-500 text-2xl" />
        </div>

        {stats.slice(0, 2).map((stat, index) => (
          <Col
            key={index}
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="p-4"
          >
            <Statistic
              title={
                loadingAnalytics ? (
                  <Skeleton />
                ) : (
                  <h1 className="text-white text-xs mb-4">{stat.title}</h1>
                )
              }
              value={stat.value}
              className="shadow-xl p-4 rounded-xl bg-blue-main h-32"
              valueStyle={{ color: "#ffffff" }}
            />
          </Col>
        ))}

        <Divider />
        <div className="w-full flex justify-center mb-4">
          <ImportOutlined className="text-blue-500 text-2xl" />
        </div>

        {stats.slice(2, 8).map((stat, index) => (
          <Col
            key={index}
            xs={24}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="p-4"
          >
            <Statistic
              title={
                loadingAnalytics ? (
                  <Skeleton />
                ) : (
                  <h1 className="text-white text-xs mb-4">{stat.title}</h1>
                )
              }
              value={stat.value}
              className="shadow-xl p-4 rounded-xl bg-blue-main h-32"
              valueStyle={{ color: "#ffffff" }}
            />
          </Col>
        ))}

        <Divider />
        <div className="w-full flex justify-center mb-4">
          <PlayCircleOutlined className="text-blue-500 text-2xl" />
        </div>

        {stats.slice(8, 10).map((stat, index) => (
          <Col
            key={index}
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="p-4"
          >
            <Statistic
              title={
                loadingAnalytics ? (
                  <Skeleton />
                ) : (
                  <h1 className="text-white text-xs mb-4">{stat.title}</h1>
                )
              }
              value={stat.value}
              className="shadow-xl p-4 rounded-xl bg-blue-main h-32"
              valueStyle={{ color: "#ffffff" }}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default EGTStats;
