import React from "react";
import Alert from "antd/es/alert/Alert";
import {
  PAGESKELETTON,
  PAGESKELETTON2,
  BASICSKELETTON,
} from "../../assets/Images";
import { BsArrowLeft } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/auth/selectors";
import LandingPageService from "../../service/LandingPageService";
import newLP from "../../assets/Images/newVorlage.png";

const activeTemplates = {
  finance: "Finanzberater",
  property: "Immobilien",
  basic: "Kostenplaner",
};

const updateTemplates = {
  stormgas: "Strom & Gas",
  individual: "Individual Landingpage",
};

const designNames = {
  finance: "Bullet",
  property: "Picture",
  basic: "Basic",
};

const bgSeter = (bg) => {
  return {
    backgroundImage: `url(${bg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "norepeat",
  };
};

const TemplateCard = ({ template, isActive, user, navigate, index }) => (
  <div
    className={`rounded-11xl relative ${
      isActive
        ? "cursor-pointer transition-transform duration-300 hover:scale-[1.02] hover:shadow-xl"
        : "cursor-not-allowed opacity-50 pointer-events-none"
    }`}
    onClick={async () => {
      if (isActive) {
        const backgroundImage =
          template === "basic"
            ? "/images/Basic.jpg"
            : `/landingPageBgs/${template}.${
                template === "finance" ? "svg" : "png"
              }`;

        const { data } = await LandingPageService.create({
          primary_image: user?.avatar,
          theme: template,
          user_id: user?._id,
          background_image: backgroundImage,
        });
        navigate(`/landingpageconfig/${template}/${data._id}`);
      }
    }}
  >
    <div
      style={
        isActive
          ? template === "basic"
            ? bgSeter("/images/Basic.jpg")
            : bgSeter(
                `/landingPageBgs/${
                  template === "finance" ? "bullet.webp" : "property.png"
                }`
              )
          : bgSeter(
              `/images/card-bg-${template === "stormgas" ? "2" : "4"}.png`
            )
      }
      className={`absolute inset-0 rounded-11xl ${
        template === "property" || template === "basic"
          ? "brightness-50 blur-[1px]"
          : ""
      }`}
    />

    {isActive && (
      <>
        <img
          src={
            template === "finance"
              ? PAGESKELETTON
              : template === "basic"
              ? BASICSKELETTON
              : PAGESKELETTON2
          }
          className={`absolute ${
            template === "finance"
              ? "h-[6.5rem] z-10 -right-[2rem] top-2 sm:h-[12rem] sm:-right-[4.5rem] sm:-top-0 md:h-[15rem] md:-top-4 md:-rigth-[2rem] lg:h-[18rem] lg:-top-[2.5rem] lg:-right-[2rem]"
              : template === "basic"
              ? "h-[4rem] top-[1rem] right-[1.5rem] sm:h-[6rem] sm:right-[5rem] sm:top-[3rem] md:h-[8rem] md:top-[1rem] md:right-[5rem] lg:h-[9rem] lg:top-[1rem] lg:right-[8rem]"
              : "h-[4rem] top-[1.7rem] -right-[0rem] sm:h-[8rem] sm:-right-[0rem] sm:top-[1.7rem] md:h-[10rem] md:top-2 md:-right-[0rem] lg:h-[12rem] lg:-top-[0rem] lg:right-[3rem]"
          }`}
        />

        <div className="absolute -top-12 -left-11">
          <img src={newLP} className="w-32 h-32" />
        </div>
      </>
    )}

    {!isActive && (
      <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-xl sm:text-3xl whitespace-nowrap text-yellow-500 z-10 font-bold animate-pulse drop-shadow-[0_0_15px_rgba(234,179,8,0.5)]">
        WIRD GEUPDATET...
      </div>
    )}

    <div
      className={`h-full relative ${
        !isActive
          ? "before:absolute before:inset-0 before:bg-[#000000] before:bg-opacity-50 before:backdrop-brightness-50 before:rounded-11xl"
          : ""
      } sm:p-10 p-5`}
    >
      <h1
        className={`sm:text-primary text-responsiveHeading ${
          isActive && (template === "property" || template === "basic")
            ? "text-white"
            : "text-[#000000]"
        } font-bold`}
      >
        {isActive
          ? "Design: " + designNames[template]
          : activeTemplates[template] || updateTemplates[template]}
      </h1>

      <p
        className={`sm:text-9xl text-xl ${
          isActive && (template === "property" || template === "basic")
            ? "text-white"
            : "text-[#000000]"
        } font-semibold`}
      >
        {"Vorlage " + (activeTemplates[template] || updateTemplates[template])}
      </p>
    </div>
  </div>
);

const Index = () => {
  const navigate = useNavigate();
  const { user } = useSelector(selectUser);

  return (
    <div className="max-w-[2000px] flex justify-center w-full m-auto">
      <div className="w-full">
        <div className="flex w-10/12 m-auto justify-between items-center my-10">
          <div className="xl:text-primary text-xl cursor-pointer text-[#868686]">
            <BsArrowLeft onClick={() => navigate(-1)} />
          </div>
          <div>
            <img
              src="/images/logo.png"
              alt=""
              className="sm:w-[50px] w-[30px]"
            />
          </div>
          <div></div>
        </div>
        <div className="text-9xl text-center font-semibold">
          <h1>Wähle hier zunächst eine für Dich passende Vorlage aus.</h1>
          <h1>Anschließend kannst Du im Konfigurator Anpassungen vornehmen.</h1>
        </div>
        <Alert
          message="Der neue Landingpage-Konfigurator ist verfügbar - probiere unbedingt die neuen Features! Du kannst ab jetzt jede Design-Vorlage für jedes Thema nutzen. Aufgrund dieser Umstellung stehen aktuell nur die Designs Bullet, Picutre und Basic zur Verfügung. Ein weiteres folgt kurzfristig. Vielen Dank für euer Verständnis."
          type="info"
          showIcon
          className="my-10 sm:w-[80%] w-[95%] text-center left-[50%] -translate-x-[50%]"
        />
        <div className="sm:w-[80%] w-[95%] m-auto flex flex-col sm:gap-[50px] gap-3 my-10">
          {/* Active Templates */}
          {Object.keys(activeTemplates).map((template) => (
            <TemplateCard
              key={template}
              template={template}
              isActive={true}
              user={user}
              navigate={navigate}
            />
          ))}

          {/* Update Templates */}
          {Object.keys(updateTemplates).map((template) => (
            <TemplateCard
              key={template}
              template={template}
              isActive={false}
              user={user}
              navigate={navigate}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Index;
