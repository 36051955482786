import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import PublicService from "../../service/PublicService";
import { sendEventEGT } from "../../utils/analytics";
import Footer from "../Footer/Index";
import Hero from "./Hero/Hero";
import HowItWorks from "./HowItWorks";
import MakeYouFit from "./MakeYouFit";
import ReviewSlider from "./MakeYouFit/ReviewSlider";
import Navbar from "./Navbar/Navbar";
import Specialities from "./Specialities";
import PeopleToPeople from "./persontoperson";
const Index = () => {
  const [reviews, setReviews] = useState([]);
  const googleAnalyticsHandler = (label) => {
    sendEventEGT({
      category: "button",
      action: "click-eg3",
      label: label,
      value: 1,
    });
  };
  useEffect(() => {
    PublicService.getTestimonials().then((res) =>
      setReviews(res.data.sort((x, y) => x.rank - y.rank))
    );
    if (window.location.pathname !== "/") {
      localStorage.redirectAfterLogin = window.location.pathname;
    } else {
      localStorage.redirectAfterLogin = "";
    }
  }, []);

  return (
    <div className="bg-white flex flex-col justify-center overflow-hidden items-center !transition-all">
      <div className="bg-[#fafafa] w-screen flex justify-center items-center px-2">
        <div className="  landing-page-hero flex flex-col items-center justify-center   max-w-[1600px]">
          <Navbar />
          <Hero />
        </div>
      </div>

      <div>
        <Specialities reviews={reviews} untenOhne />
      </div>

      <div className="hidden sm:block">
        <ReviewSlider reviews={reviews} />
      </div>
      <div className="sm:hidden">
        <div className=" mb-[50px] ">
          <ReviewSlider reviews={reviews} />
        </div>
      </div>

      <div>
        <Specialities reviews={reviews} obenOhne />
      </div>

      <div className="mt-0 mb-[5rem] max-w-[75vw] mx-5 flex-wrap primary:mx-10 flex primary:flex primary:w-auto w-full justify-center gap-3">
        <button
          onClick={() => googleAnalyticsHandler("eg3")}
          className="px-5  bg-[#FFD814] hover:bg-[#f7ca00] !text-[#000000]  animate-jump-in py-[10px] sm:mr-3  justify-center items-center flex text-center  secondary:py-[20px]   rounded-[50px] text-white font-[600] text-[20px] mt-3"
        >
          <a href="/register">Jetzt kostenlose Testphase starten</a>
        </button>
      </div>

      <div className="flex justify-center flex-col items-center tertiary:max-w-[1400px] ">
        <div className="flex py-10 sm:p-10 ">
          <HowItWorks />
        </div>
        <div className="w-full ">
          <PeopleToPeople />
        </div>
        <div className="w-full ">
          <MakeYouFit />
        </div>
      </div>
      <div className="w-full px-3 tertiary:max-w-[1600px]  bg-white border-t-[1px] border-[#d8d8d8]   mt-10">
        <Footer />
      </div>
    </div>
  );
};

export default Index;
