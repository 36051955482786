import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../footer/Footer";
import Video from "../../shared/Video";
import BasicForm from "../../shared/BasicForm";
import WhatsappButton from "../../shared/WhatsappButton";
import { sendEventUser } from "../../../../../utils/analytics";

const BasicView = ({
  landingPageData,
  type,
  promoter_id,
  landingPageId,
  user,
  promoter,
}) => {
  const { id, promoterID, clickId } = useParams();
  const [buttonType, setButtonType] = useState(
    landingPageData.activateWhatsApp
  );
  const [disableButtons, setDisableButtons] = useState(true);
  const replacePlaceholders = (text, replacements) => {
    if (typeof text !== "string") {
      console.warn("Invalid text input for replacement:", text);
      return text;
    }

    Object.keys(replacements).forEach((key) => {
      const value = replacements[key];
      text = text.replace(new RegExp(key, "g"), value);
    });

    return text;
  };
  const promoterReplacements = {
    "{Promoter Vorname}": promoter?.firstname?.trim() || "Promoter Vorname",
    "{Promoter Nachname}": promoter?.lastname?.trim() || "Promoter Nachname",
  };

  const transformText = (text) => {
    return replacePlaceholders(text || "", promoterReplacements);
  };

  const transformedIntroductoryText = transformText(
    landingPageData.introductoryText
  );
  const transformedAccompanyingTextOne = transformText(
    landingPageData.accompanyingTextOne
  );
  const transformedBasicClosingText = transformText(
    landingPageData.basicClosingText
  );

  return (
    <div className="w-full p-4 secondary:p-16">
      <div className="flex flex-col items-center secondary:px-6 py-4 space-y-6 max-w-2xl mx-auto gap-4">
        <img src={landingPageData.logoSrc} alt="logo" className="h-[100px]" />

        <div
          className="text-xl font-semibold text-center relative mb-10 overflow-anywhere whitespace-pre-line secondary:leading-[25px] leading-[25px] text-wrap"
          dangerouslySetInnerHTML={{
            __html: transformedIntroductoryText,
          }}
        ></div>

        <Video
          url={landingPageData.persentationVideo}
          thumbnail={landingPageData.thumbnail}
          className="max-w-full"
          type={"basic"}
        />

        <div
          className="text-center relative mb-10 font-semibold overflow-anywhere secondary:mt-14 whitespace-pre-line secondary:leading-[25px] leading-[25px] text-wrap"
          dangerouslySetInnerHTML={{
            __html: transformedAccompanyingTextOne,
          }}
        ></div>
        {/* 
        <WhatsappButton
          activateWhatsApp={landingPageData.activateWhatsApp}
          number={landingPageData.whatsappNumber}
          whatsappText={landingPageData.whatsappText}
          text={landingPageData.whatsappButtonTextOne}
          className="mx-4 block"
          type={type}
          sendGoogleAnalytics={() => googleAnalyticsHandler("wa1")}
        /> */}
        {buttonType ? (
          <WhatsappButton
            number={landingPageData?.whatsappNumber}
            whatsappText={"moin"}
            text={landingPageData.whatsappButtonTextOne}
            activateWhatsApp={landingPageData.activateWhatsApp}
            type="basic"
            sendGoogleAnalytics={() =>
              sendEventUser({
                category: "button",
                action: `click_wa1`,
                label: "wa1",
                value: 1,
                landingPageId: landingPageData._id,
              })
            }
          />
        ) : (
          <BasicForm
            landingPageData={landingPageData}
            promoter_id={promoter_id}
            promoter={promoter}
            clickId={clickId}
            id={id}
            user={user}
          />
        )}
        <p className="text-sm text-gray-500">{transformedBasicClosingText}</p>
      </div>
      <div className="w-full">
        <Footer
          user={user}
          ladingPageId={landingPageId}
          landingPageData={landingPageData}
          promoter_id={promoter_id}
          type={type}
        />
      </div>
    </div>
  );
};

export default BasicView;
