import { createColumnHelper } from "@tanstack/react-table";
import { Tooltip } from "antd";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import FloatLabel from "../FloatingLabel";
import { Button, FloatingLabelInput, Heading, Img } from "./components";
import { ReactTable } from "./components/ReactTable";
import UserProfileSelection from "./components/UserProfileSelection";
import FooterSection from "./FooterSection";
import WelcomeSection from "./WelcomeSection";

const Card = ({ data, handleEmpfehlen }) => {
  console.log(data);
  return (
    <div className="bg-kacheln-bg p-[15px] rounded-sm w-full">
      {/* first row */}
      <div className="flex justify-between gap-2 mb-3 itecms-center">
        {/* theme header */}
        <div className="space-y-1.5">
          <Heading as="h5" className="text-[14px] font-medium text-blue-main">
            Thema
          </Heading>
          <div className="flex flex-wrap items-center mb-3 gap-1.5">
            <Img
              src={"/images3/img_home_2.svg"}
              alt="Home Image"
              className="h-[16px] w-[16px]"
            />
            <Heading
              as="p"
              className="text-[14px] font-medium text-[#000000]-900"
            >
              {data?.themeHeader}
            </Heading>
            <Img
              src="/images3/img_arrow_left.svg"
              alt="Arrow Image"
              className="h-[10px] w-[10px]"
            />
          </div>
        </div>
        {/* deine prämie */}
        <div className="text-center space-y-1.5">
          <Heading
            as="h5"
            className="text-[14px] font-medium  text-blue_gray-400"
          >
            Deine Prämie
          </Heading>

          <Heading
            as="p"
            className="text-[14px] font-medium text-[#000000]-900"
          >
            {data?.prizeHeader}
          </Heading>
        </div>
        {/* gültig */}
        <div className="text-center space-y-1.5">
          <Heading
            as="h5"
            className="text-[14px] font-medium  text-blue_gray-400"
          >
            Gültig
          </Heading>

          <Heading
            as="p"
            className="text-[14px] font-medium text-[#000000]-900"
          >
            ∞
          </Heading>
        </div>
      </div>

      {/* second row */}
      {/* Link passend für */}

      <div className="mb-3 ">
        <Heading as="h4" className="text-[14px] font-medium text-blue_gray-400">
          Link passend für
        </Heading>
        <div className="flex justify-between w-full">
          <Heading
            as="p"
            className=" truncate text-[14px] w-full font-medium text-[#000000]-900 whitespace-nowrap max-w-[292px] truncate"
          >
            {data?.loremIpsumText}
          </Heading>
          <Tooltip title={data?.loremIpsumText}>
            <span className="flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="flex w-full ">
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="w-full"
        >
          <div className="relative">
            <Button
              shape="round"
              className="w-full min-w-[114px] rounded-[5px] px-2.5 py-1 font-semibold"
              onClick={() => {
                handleEmpfehlen(data?.promoter, data?.link);
              }}
            >
              Jetzt empfehlen
            </Button>
            <motion.div
              className="absolute inset-0 bg-white opacity-20"
              initial={{ x: "-100%" }}
              animate={{ x: "100%" }}
              transition={{
                repeat: Infinity,
                duration: 1.5,
                repeatDelay: 4,
                ease: "easeInOut",
              }}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

const steps = [
  "Thema aussuchen",
  "“Jetzt empfehlen” klicken",
  "Empfänger auswählen & senden",
];
export default function Mobile({
  promoter,
  creditsRedeem,
  setCreditRedeem,
  setErklarFilm,
  promoterData,
  handleEmpfehlen,
}) {
  const [isIOS, setIsIOS] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    // Check if the device is iOS
    const ios = /iphone|ipad|ipod/.test(
      window.navigator.userAgent.toLowerCase()
    );
    setIsIOS(ios);

    // Check if the app is already installed
    setIsStandalone(window.navigator.standalone === true);
  }, []);

  return (
    <>
      <div className="flex w-full flex-col items-center bg-[#ffffff] py-5">
        {/* welcome section */}
        <WelcomeSection mobile promoter={promoter} />
        <div className="container-xs mt-2.5 mdx:px-5">
          <div className="rounded-[14px] bg-[#fafafa] p-2.5">
            <div className="flex gap-2.5 mdx:flex-col">
              {/* First section */}
              <div className="flex-1 mdx:self-stretch">
                <div className="flex flex-col gap-[18px] rounded-[10px] border border-solid border-blue_gray-100 bg-[#ffffff] p-2.5 shadow-xs overflow-auto">
                  <div className="mx-5 mt-3.5 flex flex-col gap-5 mdx:mx-0">
                    <div className="flex flex-col items-start gap-[18px]">
                      <Heading
                        size="headingxs"
                        as="h2"
                        className="text-[16px] font-semibold text-[#000000]-900"
                      >
                        Wähle den passenden Link aus
                      </Heading>
                      <div className="space-y-[14px]">
                        {steps?.map((step, index) => (
                          <div className="flex items-center gap-2 min-w-[300px]">
                            <span className="flex !h-[24px] !w-[24px] items-center justify-center rounded-full bg-blue-main text-white">
                              {index + 1}
                            </span>
                            <Heading
                              as="h3"
                              size="headingxs"
                              className="text-[16px] font-semibold text-[#000000]-900"
                            >
                              {step}
                            </Heading>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-col gap-2.5 mt-3 w-full">
                        {promoterData.map((data, i) => (
                          <Card
                            key={i}
                            data={data}
                            handleEmpfehlen={handleEmpfehlen}
                          />
                        ))}

                        {promoterData?.length === 0 && (
                          <Heading
                            size="texts"
                            as="h3"
                            className="text-[14px] font-semibold text-[#000000]-900"
                          >
                            {promoter?.user_id?.firstname} hat noch keine Links
                            für dich aktiviert. Bitte kontaktiere{" "}
                            {promoter?.user_id?.firstname}.
                          </Heading>
                        )}
                      </div>
                      <div className="flex flex-col w-full gap-2 ">
                        <a href="#" className="flex-1">
                          <Button
                            color="light_blue_A700"
                            shape="round"
                            className="w-full rounded-[5px] font-semibold py-1"
                            onClick={() => setErklarFilm(true)}
                          >
                            Erklärfilm ansehen
                          </Button>
                        </a>

                        {!(!isIOS || isStandalone) && (
                          <div
                            className="flex-1"
                            onClick={() => {
                              alert(
                                'Um diese Seite zum Home-Bildschirm hinzuzufügen:\n\n1. Tippen Sie auf das "Teilen"-Symbol in der Browser-Leiste.\n2. Scrollen Sie nach unten und wählen Sie "Zum Home-Bildschirm hinzufügen".'
                              );
                            }}
                          >
                            <Button
                              color="blue_gray_900"
                              shape="round"
                              className="w-full rounded-[5px] font-semibold py-1"
                            >
                              Als App speichern
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="h-px bg-blue_gray-100" />
                  </div>
                </div>
              </div>

              <BalanceSection
                promoter={promoter}
                creditsRedeem={creditsRedeem}
                setCreditRedeem={setCreditRedeem}
              />
            </div>
          </div>
        </div>

        {/* footer section */}
        <FooterSection promoter={promoter} />
      </div>
    </>
  );
}

function BalanceSection({ promoter, creditsRedeem, setCreditRedeem }) {
  return (
    <div className="relative p-4 bg-white rounded-lg shadow-sm">
      <div className="flex items-center justify-center gap-6 mb-4">
        <Img src="/images/logo.png" alt="Heart Icon" className="w-[45px]" />
        <div>
          <Heading as="h6" className="text-sm font-semibold text-gray-900">
            Guthaben:
          </Heading>
          <Heading as="h1" className="!text-2xl !font-black !text-[#0084F7]">
            {String((promoter?.credits ?? 0)?.toFixed?.(2)).replace(".", ",")}€
          </Heading>
        </div>
      </div>
      <div className="flex flex-col gap-2 mb-4">
        <div className="flex items-center gap-2">
          <Img
            src="/images3/img_morflax_studio_8.png"
            alt="Checkmark"
            className="w-6 h-6"
          />
          <Heading as="p" className="text-sm font-medium text-gray-900">
            Betrag hier untern eingeben
          </Heading>
        </div>
        <div className="flex items-center gap-2">
          <Img
            src="/images3/img_morflax_studio_8.png"
            alt="Checkmark"
            className="w-6 h-6"
          />
          <Heading as="p" className="text-sm font-medium text-gray-900">
            Einlösen klicken
          </Heading>
        </div>
        <div className="flex items-center gap-2">
          <Img
            src="/images3/img_morflax_studio_8.png"
            alt="Checkmark"
            className="w-6 h-6"
          />
          <Heading as="p" className="text-sm font-medium text-gray-900">
            Innerhalb ca. 7 Tagen Gutscheincode erhalten
          </Heading>
        </div>
        <div className="flex items-center gap-2">
          <Img
            src="/images3/img_morflax_studio_8.png"
            alt="Checkmark"
            className="w-6 h-6"
          />
          <Heading as="p" className="text-sm font-medium text-gray-900">
            Code auf{" "}
            <a
              href="http://www.einloesen.de"
              className="text-blue-500 underline"
            >
              www.einloesen.de
            </a>
            &nbsp; eingeben
          </Heading>
        </div>
        <div className="flex items-center gap-2">
          <Img
            src="/images3/img_morflax_studio_8.png"
            alt="Checkmark"
            className="w-6 h-6"
          />
          <Heading as="p" className="text-sm font-medium text-gray-900">
            Gutschein oder Produkt aussuchen
          </Heading>
        </div>
        <div className="flex items-center gap-2">
          <Img
            src="/images3/img_morflax_studio_8.png"
            alt="Checkmark"
            className="w-6 h-6"
          />
          <Heading as="p" className="text-sm font-medium text-gray-900">
            Wunschprodukt per Post oder E-Mail erhalten
          </Heading>
        </div>
      </div>
      <Heading as="p" className="mb-4 text-sm font-medium text-cyan-500">
        Auswahl aus über 700 Gutscheinen und Sachprämien!
      </Heading>

      <div class="border-b border-dashed border-b-2 my-5 pt-5">
        <div class="absolute rounded-full w-5 h-5 bg-[#fafafa]  -mt-2 -left-2"></div>
        <div class="absolute rounded-full w-5 h-5 bg-[#fafafa]  -mt-2 -right-2"></div>
      </div>
      <div className="flex flex-col gap-2.5">
        <FloatLabel label={"Anzahl in Euro"}>
          <input
            required
            name="Amount Input Field"
            className="flex h-[74px] items-center justify-center rounded-[10px] border-[1.5px] border-solid border-blue_gray-100 px-5 text-[16px] font-semibold text-blue_gray-400 w-full"
            type="number"
            value={creditsRedeem}
            onChange={(e) => setCreditRedeem(e.target.value)}
          />
        </FloatLabel>

        <Button
          size="xl"
          className="self-stretch rounded-[10px] px-[34px] font-semibold !text-[#ffffff] smx:px-5"
          style={{
            backgroundImage: "url(/images3/mesh.png)",
          }}
        >
          EINLÖSEN
        </Button>
      </div>
    </div>
  );
}
