import { createColumnHelper } from "@tanstack/react-table";
import { message, Modal, Tooltip } from "antd";
import { motion } from "framer-motion";
import jwtDecode from "jwt-decode";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PromoterPublicService from "../../service/PromoterPublicService";
import PublicService from "../../service/PublicService";
import LoadingScreen from "../_NEW/LandingPage/loadingScreen/LoadingScreen";
import { Button as BlueButton } from "../Einstellungen/components";
import FloatLabel from "../FloatingLabel";
import { useScreenWidth } from "../LandingPageConfig/useScreenWidth";
import { Button, FloatingLabelInput, Heading, Img } from "./components";
import { ReactTable } from "./components/ReactTable";
import UserProfileSelection from "./components/UserProfileSelection";
import FooterSection from "./FooterSection";
import Mobile from "./Mobile";
import WelcomeSection from "./WelcomeSection";

export default function EmpfehlungsportalPage() {
  const { token } = useParams();
  const [promoter, setPromoter] = useState(null);
  const [creditsRedeem, setCreditRedeem] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [promoterLinks, setPromoterLinks] = useState([]);
  const [isIOS, setIsIOS] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);
  const [erklarFilm, setErklarFilm] = useState(false);
  const [jetztEmpfehlenModal, setJetztEmpfehlenModal] = useState(null);
  const [nachrichtZumVerschicken, setNachrichtZumVerschicken] = useState(null);

  useEffect(() => {
    if (!jetztEmpfehlenModal) setNachrichtZumVerschicken("");
    else setNachrichtZumVerschicken(jetztEmpfehlenModal.finalText);
  }, [jetztEmpfehlenModal]);

  useEffect(() => {
    // Check if the device is iOS
    const ios = /iphone|ipad|ipod/.test(
      window.navigator.userAgent.toLowerCase()
    );
    setIsIOS(ios);

    // Check if the app is already installed
    setIsStandalone(window.navigator.standalone === true);
  }, []);

  const getPromoter = useCallback(async () => {
    try {
      PromoterPublicService.getPromotionslinks(token).then((res) => {
        setPromoterLinks(res.data);
      });

      try {
        const decodedToken = jwtDecode(token);
        console.log(decodedToken);

        const { data } = await PromoterPublicService.detail(
          token,
          decodedToken.promoterId
        );
        setPromoter(data);
      } catch (error) {}
    } catch (error) {}
  }, [token]);
  useEffect(() => {
    getPromoter();
  }, [getPromoter]);

  const { width } = useScreenWidth();

  const [config, setConfig] = useState({});

  const getAdminSetting = async () => {
    const { data } = await PublicService.getCMS();
    setConfig(data);
  };

  useEffect(() => {
    getAdminSetting();
  }, []);

  const handleWhatsapp = () => {
    let finalText = nachrichtZumVerschicken.replace(
      /{Promolink}/g,
      jetztEmpfehlenModal.link
    );
    if (!finalText.includes(jetztEmpfehlenModal.link))
      finalText += ` ${jetztEmpfehlenModal.link}`;

    window.open(`https://wa.me/?text=${finalText}`, "_blank");
  };

  const handleEmpfehlen = (promoter, promoLink) => {
    const link =
      process.env.REACT_APP_SHORTENER_APP_LINK +
      `/${promoter?.shortCode}/${promoLink.num}`;

    let finalText = promoLink?.begleittext
      ?.replace(/{Promoter Vorname}/g, promoter?.firstname)
      .replace(/{Promoter Nachname}/g, promoter?.lastname)
      ?.replace(/{Partner Vorname}/g, promoter?.user_id?.firstname)
      .replace(/{Partner Nachname}/g, promoter?.user_id?.lastname);

    if (!finalText.includes("{Promolink}")) finalText += ` {Promolink}`;

    setJetztEmpfehlenModal({ ...promoLink, finalText, link });
  };
  const tableColumns = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper();
    return [
      tableColumnHelper.accessor("themeHeader", {
        cell: (info) => (
          <div className="flex items-center mb-3 gap-1.5 w-[210px]">
            <Img
              src={info.row.original.homeImage}
              alt="Home Image"
              className="h-[16px] w-[16px]"
            />
            <Heading
              as="p"
              className="text-[14px] font-medium text-[#000000]-900 max-w-[200px] overflow-hidden truncate"
            >
              {info.getValue()}
            </Heading>
            <Link
              target="_blank"
              to={
                process.env.REACT_APP_SHORTENER_APP_LINK +
                `/${promoter?.shortCode}/${info.row.original?.link?.num}`
              }
            >
              <Img
                src="/images3/img_arrow_left.svg"
                alt="Arrow Image"
                className="h-[10px] w-[10px]"
              />
            </Link>
          </div>
        ),
        header: (info) => (
          <div className="flex mb-3">
            <Heading
              as="h3"
              className="text-[14px] font-medium text-blue_gray-400"
            >
              Thema
            </Heading>
          </div>
        ),
        meta: { width: "166px" },
      }),
      tableColumnHelper.accessor("linkHeader", {
        cell: (info) => (
          <div className="flex flex-1 items-center mb-3 justify-start mr-4   gap-[18px] ">
            <Heading
              as="p"
              className="self-center text-[14px] font-medium text-[#000000]-900 whitespace-nowrap max-w-[292px] truncate"
            >
              {info.getValue()}
            </Heading>
            <Tooltip title={info.row.original.loremIpsumText}>
              <Img
                src="/images3/img_inbox.svg"
                alt="Inbox Image"
                className="h-[14px] w-[14px]"
              />
            </Tooltip>
          </div>
        ),
        header: (info) => (
          <div className="flex mb-3">
            <Heading
              as="h4"
              className="text-[14px] font-medium text-blue_gray-400"
            >
              Link passend für
            </Heading>
          </div>
        ),
        meta: { width: "376px" },
      }),
      tableColumnHelper.accessor("prizeHeader", {
        cell: (info) => (
          <div className="flex mb-3">
            <Heading
              as="p"
              className="text-[14px] font-medium text-[#000000]-900"
            >
              {info.getValue()}
            </Heading>
          </div>
        ),
        header: (info) => (
          <div className="flex mb-3">
            <Heading
              as="h5"
              className="text-[14px] font-medium mb-3 text-blue_gray-400"
            >
              Deine Prämie
            </Heading>
          </div>
        ),
        meta: { width: "124px" },
      }),
      tableColumnHelper.accessor("validityHeader", {
        cell: (info) => (
          <div className="flex self-end mb-3">
            <Heading
              as="p"
              className="text-[14px] font-medium text-[#000000]-900"
            >
              {info.getValue()}
            </Heading>
          </div>
        ),
        header: (info) => (
          <div className="flex mb-3">
            <Heading
              as="h6"
              className="text-[14px] font-medium text-blue_gray-400 mb-3"
            >
              Gültig
            </Heading>
          </div>
        ),
        meta: { width: "76px" },
      }),
      tableColumnHelper.accessor("actionHeader", {
        cell: (info) => (
          <div className="flex mb-3">
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <div className="relative">
                <Button
                  shape="round"
                  className="min-w-[114px] rounded-[5px] px-2.5 py-1 font-semibold"
                  onClick={() => {
                    handleEmpfehlen(
                      info.row.original.promoter,
                      info.row.original.link
                    );
                  }}
                >
                  Jetzt empfehlen
                </Button>
                <motion.div
                  className="absolute inset-0 bg-white opacity-20"
                  initial={{ x: "-100%" }}
                  animate={{ x: "100%" }}
                  transition={{
                    repeat: Infinity,
                    duration: 1.5,
                    repeatDelay: 4,
                    ease: "easeInOut",
                  }}
                />
              </div>
            </motion.div>
          </div>
        ),
        header: (info) => (
          <div className="flex mb-3">
            <Heading
              as="p"
              className="text-[14px] font-medium mb-3 text-blue_gray-400"
            >
              Aktion
            </Heading>
          </div>
        ),
        meta: { width: "114px" },
      }),
    ];
  }, []);

  if (!promoter) {
    return <LoadingScreen />;
  }

  const promoterData = promoterLinks.map((link) => ({
    homeImage: link?.linkKonfig?.theme?.icon,
    themeHeader: link?.linkKonfig?.theme?.label,
    shieldCheckImageDuplicate: link?.linkKonfig?.theme?.icon,
    insuranceTextDuplicate: link?.landingpage_id?.name,
    arrowLeftShieldImageDuplicate: "/images3/img_arrow_left.svg",
    linkHeader:
      (link?.linkKonfig?.description ?? "")?.slice?.(0, 40) +
      ((link?.linkKonfig?.description ?? "")?.length > 40 ? "..." : ""),
    loremIpsumText: link?.linkKonfig?.description ?? "",
    inboxLoremImage: "/images3/img_inbox.svg",
    prizeHeader: link?.eurProKunde + "€",
    priceTextSecond: link?.eurProKunde + "€",
    validityHeader: "∞",
    validityTextSecond: "∞",
    actionHeader: "x",
    link,
    promoter: link?.promoter_id,
  }));

  if (width < 760)
    return (
      <Mobile
        promoter={promoter}
        creditsRedeem={creditsRedeem}
        setCreditRedeem={setCreditRedeem}
        setErklarFilm={setErklarFilm}
        promoterData={promoterData}
        handleEmpfehlen={handleEmpfehlen}
      />
    );
  return (
    <>
      <div className="flex w-full flex-col items-center bg-[#ffffff] py-5">
        {/* welcome section */}
        <WelcomeSection promoter={promoter} />
        <div className="container-xs mt-2.5 mdx:px-5">
          <div className="rounded-[14px] bg-[#fafafa] p-2.5">
            <div className="flex gap-2.5 mdx:flex-col">
              <div className="flex-1 mdx:self-stretch">
                <div className="flex flex-col gap-[18px] rounded-[10px] border border-solid border-blue_gray-100 bg-[#ffffff] p-2.5 shadow-xs mdx:overflow-auto">
                  <div className="mx-5 mt-3.5 flex flex-col gap-5 mdx:mx-0">
                    <div className="flex flex-col items-start gap-[18px]">
                      <Heading
                        size="headingxs"
                        as="h2"
                        className="text-[16px] font-semibold text-[#000000]-900"
                      >
                        Wähle den passenden Link aus
                      </Heading>
                      <ReactTable
                        bodyProps={{ className: "" }}
                        className="self-stretch smx:block smx:overflow-x-auto smx:whitespace-nowrap"
                        columns={tableColumns}
                        data={promoterData}
                        promoter={promoter}
                      />
                    </div>
                    <div className="h-px bg-blue_gray-100" />
                  </div>
                  <div className="mx-5 flex mdx:mx-0 mdx:flex-col">
                    <UserProfileSelection />
                    <Img
                      src="/images3/img_zeichenfla_che_2_4x.png"
                      alt="List Image"
                      className="mb-6 mt-[120px] mx-[-30px] h-[40px] w-[8%] object-contain mdx:w-full"
                      style={{ zIndex: 999 }}
                    />
                    <UserProfileSelection
                      headingText="02 Klicken"
                      descriptionText="Klicke auf den “Jetzt empfehlen” Button, Nachrichtentext prüfen und ggf. anpassen."
                      userImage="/images3/excl export.png"
                    />
                    <Img
                      src="/images3/img_zeichenfla_che_2_4x_62x62.png"
                      alt="Zeichenflache"
                      className="mb-[110px] mx-[-20px] mt-3 h-[62px] w-[6%] object-contain mdx:w-full"
                      style={{ zIndex: 999 }}
                    />
                    <UserProfileSelection
                      headingText="03 Versenden"
                      descriptionText="Jetzt versenden klicken und dein WhatsApp öffnet sich. Dort einfach die gewünschten Empfehlungen auswählen und versenden."
                      userImage="/images3/img_volume.svg"
                      className="gap-1"
                    />
                    <Img
                      src="/images3/img_zeichenfla_che_2_4x_28x66.png"
                      alt="Zeichenflache"
                      className="mb-[124px] mx-[-25px] mt-[34px] h-[28px] w-[8%] object-contain mdx:w-full"
                      style={{ zIndex: 999 }}
                    />
                    <UserProfileSelection
                      headingText="04 Nochmal"
                      descriptionText="Wiederhole die Schritte 1-3 um weitere Links zu versenden. So kannst du deinen Bekannten immer den richtigen Link für Ihre aktuelle Situation senden."
                      userImage="/images3/img_arrows_repeat_1.svg"
                      className="gap-1"
                    />
                  </div>
                  <div className="flex justify-between gap-2">
                    <a href="#" className="flex-1">
                      <Button
                        color="light_blue_A700"
                        shape="round"
                        className="w-full rounded-[5px] font-semibold py-1"
                        onClick={() => setErklarFilm(true)}
                      >
                        Erklärfilm ansehen
                      </Button>
                    </a>
                    {!(!isIOS || isStandalone) && (
                      <div
                        className="flex-1 cursor-pointer"
                        onClick={() => {
                          alert(
                            'Um diese Seite zum Home-Bildschirm hinzuzufügen:\n\n1. Tippen Sie auf das "Teilen"-Symbol in der Browser-Leiste.\n2. Scrollen Sie nach unten und wählen Sie "Zum Home-Bildschirm hinzufügen".'
                          );
                        }}
                      >
                        <Button
                          color="blue_gray_900"
                          shape="round"
                          className="w-full rounded-[5px] font-semibold py-1"
                        >
                          Als App speichern
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="h-[544px] w-[32%] rounded-[10px] bg-cover bg-no-repeat px-2.5 py-3 mdx:h-auto mdx:w-full"
                style={{
                  backgroundImage: "url(/images3/img_group_69.svg)",
                }}
              >
                <div className="mt-1.5 flex flex-col gap-6">
                  <div className="mx-5 flex flex-col gap-[42px] mdx:mx-0">
                    <div className="flex flex-col gap-2.5">
                      <div className="flex items-start justify-between gap-5 px-[52px] mdx:px-5">
                        <Img
                          src="/images3/img_user_blue_gray_900.svg"
                          alt="User Image"
                          className="mt-3 h-[52px] w-[22%] object-contain"
                        />
                        <div className="flex flex-col items-start self-center">
                          <Heading
                            size="headingxs"
                            as="h6"
                            className="text-[16px] font-semibold text-[#000000]-900"
                          >
                            Guthaben:
                          </Heading>
                          <Heading
                            size="headingmd"
                            as="h1"
                            className="text-[42px] font-bold text-light_blue-a700 mdx:text-[38px] smx:text-[32px]"
                          >
                            {String(
                              (promoter?.credits ?? 0)?.toFixed?.(2)
                            ).replace(".", ",")}
                            €
                          </Heading>
                        </div>
                      </div>
                      <div className="flex flex-col items-start gap-2.5">
                        <div className="flex flex-col items-start gap-0.5 self-stretch">
                          <div className="flex items-center">
                            <Img
                              src="/images3/img_morflax_studio_8.png"
                              alt="Morflax Studio Image"
                              className="h-[24px] object-cover"
                            />
                            <Heading
                              as="p"
                              className="ml-1 self-end text-[14px] font-medium text-[#000000]-900"
                            >
                              Betrag hier untern eingeben
                            </Heading>
                          </div>
                          <div className="flex items-center">
                            <Img
                              src="/images3/img_morflax_studio_8.png"
                              alt="Redeem Image"
                              className="h-[24px] object-cover"
                            />
                            <Heading
                              as="p"
                              className="ml-1 text-[14px] font-medium text-[#000000]-900"
                            >
                              Einlösen klicken
                            </Heading>
                          </div>
                          <div className="flex items-center">
                            <Img
                              src="/images3/img_morflax_studio_8.png"
                              alt="Voucher Code Image"
                              className="h-[24px] object-cover"
                            />
                            <Heading
                              as="p"
                              className="ml-1 self-end text-[14px] font-medium text-[#000000]-900"
                            >
                              Innerhalb ca. 7 Tagen Gutscheincode erhalten
                            </Heading>
                          </div>
                          <div className="flex items-center">
                            <Img
                              src="/images3/img_morflax_studio_8.png"
                              alt="Redeem Instruction Image"
                              className="h-[24px] object-cover"
                            />
                            <Heading
                              as="p"
                              className="ml-1 flex self-end text-[14px] font-medium text-[#000000]-900"
                            >
                              <span className="text-[#000000]-900">
                                Code auf&nbsp;
                              </span>
                              <a
                                href="#"
                                className="inline text-light_blue-a700 underline"
                              >
                                www.einloesen.de
                              </a>
                              &nbsp; eingeben
                            </Heading>
                          </div>
                          <div className="flex items-center">
                            <Img
                              src="/images3/img_morflax_studio_8.png"
                              alt="Product Selection Image"
                              className="h-[24px] object-cover"
                            />
                            <Heading
                              as="p"
                              className="ml-1 text-[14px] font-medium text-[#000000]-900"
                            >
                              Gutschein oder Produkt aussuchen
                            </Heading>
                          </div>
                          <div className="flex items-center">
                            <Img
                              src="/images3/img_morflax_studio_8.png"
                              alt="Receive Product Image"
                              className="h-[24px] object-cover"
                            />
                            <Heading
                              as="p"
                              className="ml-1 self-end text-[14px] font-medium text-[#000000]-900"
                            >
                              Wunschprodukt per Post oder E-Mail erhalten
                            </Heading>
                          </div>
                        </div>
                        <Heading
                          as="p"
                          className="text-[14px] font-medium text-cyan-a400"
                        >
                          Auswahl aus über 700 Gutscheinen und Sachprämien!
                        </Heading>
                      </div>
                    </div>
                    <div
                      className="mx-[18px] h-[2px] mdx:mx-0 mb-[40px] mt-[10px]"
                      style={{
                        background:
                          "repeating-linear-gradient(to right, #94A3B8 0, #94A3B8 12px, transparent 12px, transparent 16px)",
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-2.5">
                    <FloatLabel label={"Anzahl in Euro"}>
                      <input
                        required
                        name="Amount Input Field"
                        type="number"
                        className="flex h-[74px] items-center justify-center rounded-[10px] border-[1.5px] border-solid border-blue_gray-100 px-5 text-[16px] font-semibold text-blue_gray-400 w-full"
                        value={creditsRedeem}
                        onChange={(e) => setCreditRedeem(e.target.value)}
                      />
                    </FloatLabel>

                    <Button
                      size="xl"
                      className="self-stretch rounded-[10px] px-[34px] font-semibold !text-[#ffffff] smx:px-5"
                      style={{
                        backgroundImage: "url(/images3/mesh.png)",
                      }}
                      onClick={() => {
                        if (submitting) return;
                        setSubmitting(true);
                        PromoterPublicService.createRedeemRequest(
                          token,
                          creditsRedeem
                        )
                          .then((e) => {
                            setPromoter((prev) => ({
                              ...prev,
                              credits: prev.credits - creditsRedeem,
                            }));

                            setCreditRedeem(0);
                          })
                          .catch((e) => console.log(e))
                          .finally(() => setSubmitting(false));
                      }}
                    >
                      EINLÖSEN
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer section */}
        <FooterSection promoter={promoter} />
      </div>

      <Modal
        open={erklarFilm}
        onCancel={() => setErklarFilm(false)}
        footer={[]}
      >
        {config?.CMS?.erklarFilm && (
          <div className="flex flex-col items-center justify-center  w-full">
            <video
              playsInline
              controls
              className="aspect-video w-full rounded-sm object-cover shadow-lg bg-black bg-opacity-50 p-1"
              poster={config.CMS.erklarFilmThumbnail}
              style={{ cursor: "pointer" }}
            >
              <source src={config?.CMS?.erklarFilm} type="video/mp4" />
              <source src={config?.CMS?.erklarFilm} type="video/webm" />
              <source src={config?.CMS?.erklarFilm} type="video/quicktime" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </Modal>

      <Modal
        wrapClassName="standardRoot-modal"
        footer={[]}
        onCancel={() => setJetztEmpfehlenModal(null)}
        closeIcon={<></>}
        open={jetztEmpfehlenModal}
        title=""
      >
        <div className="ml-1 mb-5 flex items-center justify-between gap-5 md:ml-0">
          <Heading
            size="desktop_label_bold"
            as="h1"
            className="self-end text-[20px] font-semibold text-[#000000]"
          >
            Empfehlung verschicken
          </Heading>
          <img
            src="/images/img_cross_small_1.svg"
            alt="Close Icon"
            className="h-[32px] cursor-pointer"
            onClick={() => setJetztEmpfehlenModal(null)}
          />
        </div>

        {/* {JSON.stringify()} */}

        <FloatLabel
          label={"Typ"}
          unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
          className={" flex-grow"}
          value={jetztEmpfehlenModal?.linkKonfig?.theme?.label}
          disabled
        >
          <div className="w-full flex justify-between items-center ">
            <input
              type="text"
              className="border-[1px] border-[#d8d8d8] outline-none rounded-[15px] p-[15px_12.5px] w-full !appearance-none"
              value={jetztEmpfehlenModal?.linkKonfig?.theme?.label}
              disabled
            />
            <img
              src={jetztEmpfehlenModal?.linkKonfig?.theme?.icon}
              alt="Arrow Up"
              className="h-[24px] w-[20px] object-contain absolute right-3"
            />
          </div>
        </FloatLabel>

        <br />

        <FloatLabel
          label={"Dein Empfehlungslink"}
          unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
          className={" flex-grow"}
          value={jetztEmpfehlenModal?.link}
          disabled
        >
          <div className="w-full flex justify-between items-center ">
            <input
              type="text"
              className="border-[1px] border-[#d8d8d8] outline-none rounded-[15px] p-[15px_12.5px] w-full !appearance-none"
              value={jetztEmpfehlenModal?.link}
              disabled
            />

            <img
              src="/images/clone 1.svg"
              alt="Arrow Up"
              className="h-[24px] w-[20px] object-contain absolute right-3 cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(jetztEmpfehlenModal?.link);
                message.info("Kopiert");
              }}
            />
          </div>
        </FloatLabel>

        <br />

        <FloatLabel
          label={"Für wen dieser Link geeignet ist"}
          unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
          className={" flex-grow"}
          value={jetztEmpfehlenModal?.linkKonfig?.description}
          disabled
        >
          <div className="w-full flex justify-between items-center ">
            <textarea
              rows={4}
              className="border-[1px] border-[#d8d8d8] outline-none rounded-[15px] p-[15px_12.5px] w-full !appearance-none"
              value={jetztEmpfehlenModal?.linkKonfig?.description}
              disabled
            />
          </div>
        </FloatLabel>

        <br />

        <FloatLabel
          label={"Deine Nachricht"}
          unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
          className={" flex-grow"}
          value={nachrichtZumVerschicken}
          onChange={(e) => setNachrichtZumVerschicken(e.target.value)}
        >
          <div className="w-full flex justify-between items-center ">
            <textarea
              rows={4}
              className="border-[1px] border-[#d8d8d8] outline-none rounded-[15px] p-[15px_12.5px] w-full !appearance-none"
              value={nachrichtZumVerschicken}
              onChange={(e) => setNachrichtZumVerschicken(e.target.value)}
              onKeyDown={(e) => {
                if (e.code === "Enter" && e.ctrlKey) {
                  e.preventDefault();
                  handleWhatsapp();
                }
              }}
            />
          </div>
        </FloatLabel>

        <BlueButton
          shape="round"
          className="w-full ml-1 mt-[200px] rounded-[10px] px-[34px] md:ml-0 sm:px-5"
          onClick={handleWhatsapp}
        >
          Jetzt per WhatsApp versenden
        </BlueButton>
      </Modal>
    </>
  );
}
