import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PublicService from "../../service/PublicService";
import QuestionService from "../../service/QuestionService";
import LoadingScreen from "../_NEW/LandingPage/loadingScreen/LoadingScreen";
import Questionare from "./Questionare";
import { Finance } from "./data/Data";
const MainQuestionare = () => {
  const { type, id, promoter_id, clickId } = useParams();
  const [questions, setQuestions] = useState(null);
  const [totalRejectionAnswers, setTotalRejectionAnswers] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!type || !id || !promoter_id || !clickId) {
      setIsLoading(false);
      return;
    }
    PublicService.getQuestions(id)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          let totalRejectionAnswers = 0;

          data.forEach((question) => {
            if (Array.isArray(question.answerOptions)) {
              question.answerOptions.forEach((answer) => {
                if (!answer.consideredNiceToHaveAnswer) {
                  totalRejectionAnswers++;
                }
              });
            }
          });

          setTotalRejectionAnswers(totalRejectionAnswers);
          setQuestions(data);
        } else {
          console.error(
            "Die empfangenen Daten sind nicht im erwarteten Format:",
            data
          );
        }
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Fragen:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  if (isLoading || !questions) {
    return <LoadingScreen />;
  }

  switch (type) {
    case "finance":
      return (
        <Questionare
          data={questions?.map?.((e) => ({
            question: e.questionText,
            answers: e.answerOptions ?? [],
            totalRejectionAnswers: totalRejectionAnswers,
            bg: e.backgroundImage,
          }))}
          styles={{
            modelBg: "rgba(250, 250, 250, 0.30)",
            formBgOne: questions[0]?.backgroundImage,
            formBgTwo: "white",
            font: "Manrope",
            rounded: true,
            buttonbg: "#001D47",
          }}
        />
      );

    case "stormgas":
      return (
        <Questionare
          data={questions?.map?.((e) => ({
            question: e.questionText,
            answers: e.answerOptions ?? [],
            totalRejectionAnswers: totalRejectionAnswers,
            bg: e.backgroundImage,
          }))}
          styles={{
            modelBg: "rgba(70, 70, 174, 0.60)",
            formBgOne: questions[0]?.backgroundImage,
            formBgTwo: "#DBEF5D",
            font: "Lexend",
            rounded: false,
            buttonbg: "#4646AE",
          }}
        />
      );

    case "property":
      return (
        <Questionare
          data={questions?.map?.((e) => ({
            question: e.questionText,
            answers: e.answerOptions ?? [],
            totalRejectionAnswers: totalRejectionAnswers,
            bg: e.backgroundImage,
          }))}
          styles={{
            modelBg: "rgba(241, 243, 246, 0.8)",
            formBgOne: questions[0]?.backgroundImage,
            formBgTwo: "white",
            font: "Roboto Slab",
            rounded: true,
            buttonbg: "#0084F7",
          }}
        />
      );

    case "individual":
      return (
        <Questionare
          data={questions?.map?.((e) => ({
            question: e.questionText,
            answers: e.answerOptions ?? [],
            totalRejectionAnswers: totalRejectionAnswers,
            bg: e.backgroundImage,
          }))}
          styles={{
            modelBg: "rgba(250, 250, 250, 0.30)",
            formBgOne: questions[0]?.backgroundImage,
            formBgTwo: "white",
            font: "Manrope",
            rounded: true,
            buttonbg: "#001D47",
          }}
        />
      );

    default:
      return <></>;
  }
};

export default MainQuestionare;
