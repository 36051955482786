import Compressor from "compressorjs";

export const compressImageToMaxSize = async (base64Image, maxSizeInBytes) => {
  console.log("Starte Kompression...");
  console.log("Maximale Größe in Bytes:", maxSizeInBytes);

  let compressedImage = base64Image;
  let quality = 0.8; 

  while (true) {
    const blob = dataURItoBlob(compressedImage);
    console.log(`Aktuelle Größe des Blobs: ${blob.size} Bytes`);

    if (blob.size <= maxSizeInBytes) {
      console.log("Kompression erfolgreich. Bildgröße unter dem Limit.");
      return compressedImage; 
    }

    console.log(`Komprimiere weiter... Aktuelle Qualität: ${quality}`);
    try {
      compressedImage = await new Promise((resolve, reject) => {
        new Compressor(blob, {
          quality,
          success: (compressedBlob) => {
            console.log(
              `Neue Blob-Größe nach Kompression: ${compressedBlob.size} Bytes`
            );
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = (error) => {
              console.error(
                "Fehler beim Lesen des komprimierten Blobs:",
                error
              );
              reject(error);
            };
            reader.readAsDataURL(compressedBlob);
          },
          error: (error) => {
            console.error("Fehler bei der Kompression:", error);
            reject(error);
          },
        });
      });
    } catch (error) {
      console.error("Fehler während der Kompression:", error);
      throw error; 
    }

    quality -= 0.1; 
    console.log(`Neue Qualität nach Reduktion: ${quality}`);

    if (quality < 0.2) {
      console.warn("Kompression abgebrochen. Qualität zu niedrig.");
      break;
    }
  }

  console.log(
    "Kompression beendet. Rückgabe des letzten komprimierten Bildes."
  );
  return compressedImage; 
};

// Hilfsfunktion zur Umwandlung von base64 in Blob
const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};
