import React from "react";
import { Heading } from "./components";

export default function FooterSection({ promoter }) {
  return (
    <>
      {/* footer section */}
      <div className="self-stretch mt-6">
        <div className="flex flex-col items-center gap-[22px]">
          <div className="self-stretch w-full h-px bg-blue_gray-100" />
          <div className="container-xs mdx:px-5">
            <div className="flex flex-wrap items-center justify-center gap-x-[31px] mdx:gap-x-[6px]">
              <Heading
                as="h2"
                className="text-[14px] mdx:text-[8px] font-medium text-[#000000]-900 mr-auto"
              >
                © Copyright EinGuterTipp
              </Heading>
              <a
                href={`${window.location.origin}/teilnahmebedingungen/${promoter?.user_id?._id}`}
                target="_blank"
                rel="noreferrer"
              >
                <Heading
                  as="h3"
                  className="text-[14px] mdx:text-[8px] font-medium text-[#000000]-900"
                >
                  Teilnahmebedingungen
                </Heading>
              </a>
              <a
                href={`${window.location.origin}/impressum/${promoter?.user_id?._id}`}
                target="_blank"
                rel="noreferrer"
              >
                <Heading
                  as="h4"
                  className="text-[14px] mdx:text-[8px] font-medium text-[#000000]-900"
                >
                  Impressum
                </Heading>
              </a>
              <a href="/datenschutz" target="_blank" rel="noreferrer">
                <Heading
                  as="h5"
                  className="text-[14px] mdx:text-[8px] font-medium text-[#000000]-900"
                >
                  Datenschutz
                </Heading>
              </a>
              {/* <div className="flex flex-1 flex-wrap justify-end gap-[31px] smx:self-stretch">
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
