import React, { useEffect, useRef, useState } from "react";
import {
  handleEnded,
  handlePause,
  handlePlay,
} from "../../../../utils/analytics";

const Video = ({
  url,
  className,
  textWhite = false,
  thumbnail,
  type,
  heading,
  landingPageId,
  videoTextBG,
  activateBackgroundAccompanyingTextOne,
  accompanyingTextOne,
  autoPlay = false,
}) => {
  const videoRef = useRef();
  const pRef = useRef(null);
  const cRef = useRef(null);
  const [firstPlay, setFirstPlay] = useState(true);
  const [watchtime, setWatchtime] = useState(0);
  const [pWidth, setPWidth] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(null);

  useEffect(() => {
    const updatePWidth = () => {
      if (videoRef.current && pRef.current) {
        const videoWidth = videoRef.current.offsetWidth;
        const videoHeight = videoRef.current.offsetHeight;
        const bounceButton = document.getElementById("bounce-button");
        const buttonHeight = bounceButton ? bounceButton.offsetHeight * 2 : 0;

        setPWidth(videoWidth);
        pRef.current.style.width = `${videoWidth}px`;

        // Only adjust height on desktop (secondary breakpoint)
        if (window.innerWidth >= 1370) {
          // Assuming 1024px is your secondary breakpoint
          cRef.current.style.height = `${videoHeight + buttonHeight}px`;
        } else {
          cRef.current.style.height = `${videoHeight}px`;
        }
      }
    };

    // Initial width setup
    updatePWidth();

    // Add resize listener
    const resizeObserver = new ResizeObserver(updatePWidth);
    if (videoRef.current) {
      resizeObserver.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        resizeObserver.unobserve(videoRef.current);
      }
      resizeObserver.disconnect();
    };
  }, []);

  // Erkennung der Video-Dimensionen
  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      const { videoWidth, videoHeight } = videoRef.current;
      if (videoWidth && videoHeight) {
        const ratio = `${videoWidth} / ${videoHeight}`;
        setAspectRatio(ratio);
      }
    }
  };

  const withBackground = (set) =>
    set ? "bg-[#000000] p-1 rounded-sm bg-opacity-50" : "";

  const googleAnalyticsHandler = (action) => {
    const handlers = {
      play: () =>
        handlePlay(
          videoRef,
          setWatchtime,
          firstPlay,
          setFirstPlay,
          landingPageId,
          "v1",
          true,
          false
        ),
      pause: () =>
        handlePause(
          videoRef,
          watchtime,
          setWatchtime,
          landingPageId,
          "v1",
          true,
          false
        ),
      end: () =>
        handleEnded(
          videoRef,
          watchtime,
          setWatchtime,
          landingPageId,
          "v1",
          true,
          false
        ),
    };
    handlers[action]();
  };

  const videoElement = (customClass) => (
    <video
      ref={videoRef}
      onPlay={() => googleAnalyticsHandler("play")}
      onPause={() => googleAnalyticsHandler("pause")}
      onEnded={() => googleAnalyticsHandler("end")}
      onLoadedMetadata={handleLoadedMetadata}
      playsInline
      poster={thumbnail}
      controls
      // style={aspectRatio ? { aspectRatio } : undefined}
      className={`${customClass} w-full object-cover shadow-[0_8px_30px_rgb(0,0,0,0.12)]`}
      autoPlay={autoPlay}
    >
      <source src={url} type="video/mp4" />
      <source src={url} type="video/webm" />
      <source src={url} type="video/quicktime" />
      Your browser does not support the video tag.
    </video>
  );

  return (
    <div
      className={`${className} mx-auto video-container ${
        type !== "finance"
          ? "xs:w-[500px] primary:w-[600px] secondary:w-[900px]"
          : ""
      } ${textWhite ? "text-white" : "text-[#000000]"}`}
    >
      {type === "finance" && (
        <div className="flex flex-col w-full gap-2">
          <div className="flex w-full secondary:max-h-[800px] tertiary:max-h-[1000px] secondary:gap-8 items-start">
            <div className="secondary:w-[100%] w-full">
              <div className="relative w-full h-full flex flex-col gap-4">
                <div className="flex w-full justify-center">
                  <div className="flex secondary:w-[100%] flex-col max-w-[100%]">
                    <p
                      ref={pRef}
                      className={`secondary:mb-4 secondary:text-[1rem] text-[0.75rem] mb-4 text-none ${
                        videoTextBG
                          ? withBackground(heading !== "" && videoTextBG)
                          : ""
                      }`}
                      dangerouslySetInnerHTML={{ __html: heading }}
                    ></p>
                    <div className="flex flex-row w-full secondary:h-[100%] secondary:gap-0">
                      <div className="flex flex-col w-full secondary:h-[100%]">
                        <div className="secondary:w-[90%]">
                          {videoElement(
                            "rounded-sm max-h-[30vh] secondary:max-h-[60vh]"
                          )}
                        </div>
                        <a
                          id="bounce-button"
                          href="#middle"
                          className="mt-4 pt-14 ml-[40%] hidden secondary:block"
                        >
                          <img
                            src="/images/icons-8-doppelt-runter-21.png"
                            alt=""
                            className="animate-bounce secondary:w-[80px] sm:w-[50px] w-[32px] m-auto"
                          />
                        </a>
                      </div>
                      <div
                        ref={cRef}
                        className={`text-2xl font-semibold overflow-anywhere ${
                          !activateBackgroundAccompanyingTextOne
                            ? "secondary:-mt-[0.85rem]"
                            : ""
                        } secondary:w-[40%] secondary:leading-[25px] overflow-y-scroll whitespace-pre-line ${withBackground(
                          accompanyingTextOne !== "" &&
                            activateBackgroundAccompanyingTextOne
                        )}`}
                        dangerouslySetInnerHTML={{
                          __html: accompanyingTextOne,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {type === "property" && (
        <div>
          <p
            className={`secondary:mb-4 secondary:text-[1rem] text-[0.75rem] mb-4 text-none text-[#000000]`}
            dangerouslySetInnerHTML={{ __html: heading }}
          ></p>
          <div>{videoElement("w-[400px] w-full rounded-sm")}</div>
        </div>
      )}

      {type !== "property" && type !== "finance" && (
        <div>{videoElement("w-[400px] w-full rounded-sm")}</div>
      )}
    </div>
  );
};

export default Video;
