const GUTERTIPCARD = "/images/gutertipcard.png";

const DOWNGREENARROW = "/images/downArrow.png";

const RIGhTGREENARROW = "/images/rightarrow.png";

const CURVEDARROW = "/images/curvedarrow.png";

const PAGESKELETTON = "/images/pageskelet.png";

const PAGESKELETTON2 = "/images/pageskelet2.png";

const BASICSKELETTON = "/images/BASICSKELETTON.png";

export {
  GUTERTIPCARD,
  DOWNGREENARROW,
  RIGhTGREENARROW,
  CURVEDARROW,
  PAGESKELETTON,
  PAGESKELETTON2,
  BASICSKELETTON
};
