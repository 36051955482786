import React from "react";
import { imageBackground } from "../../../../../utils/setImageBackground";

const PortraitAndText = ({ className, landingPageData }) => {
  const {
    transformed_accompanyingTextTwo,
    transformed_accompanyingTextThree,
    primary_image,
  } = landingPageData;

  const isEmptyHtml = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText.trim() === "";
  };

  const renderTextBlock = (text, className = "") => (
    <p
      className={`relative ${className}`}
      dangerouslySetInnerHTML={{ __html: text }}
    ></p>
  );
  const shouldHideTextBlockTwo = isEmptyHtml(
    landingPageData.transformed_accompanyingTextTwo
  );

  console.log(landingPageData.theme);
  return (
    <div
      className={`${className} min-h-[250px] px-4 xs:px-20 gap-7 primary:px-40 container-hide-scrollbar`}
    >
      <img
        src={primary_image}
        className="float-right xs:max-h-[70vh] !sm:max-h-[70vh] xs:object-cover w-full rounded-xl object-contain"
        alt="Description"
      />
      <div className="box-border w-full">
        <div className="font-bold whitespace-pre-line">
          {!shouldHideTextBlockTwo && (
            <div className="rounded-sm bg-slate-900 text-white p-4 mb-4 break-words text-whiteImportant">
              {renderTextBlock(transformed_accompanyingTextTwo)}
            </div>
          )}
          {transformed_accompanyingTextThree !== "<p><br></p>" &&
            landingPageData.theme === "finance" && (
              <div className="p-4 bg-[#fafafa] rounded-sm break-words">
                {renderTextBlock(transformed_accompanyingTextThree)}
              </div>
            )}
        </div>
      </div>
      <div className="clearfix"></div>
    </div>
  );
};

export default PortraitAndText;
