import React, { useState } from "react";
import { Collapse } from "react-collapse";

const CollapseItem = ({
  title,
  children,
  icon,
  defaultOpen = false,
  notopen = false,
  spaceOben,
}) => {
  const [open, setOpen] = useState(defaultOpen);

  const handletoggle = () => {
    if (notopen) {
      return setOpen(false);
    }
    setOpen(!open);
  };

  return (
    <div
      className={`w-full rounded-11xl ${
        spaceOben ? "mt-5" : ""
      } bg-kacheln-bg   px-[1rem] overflow-auto`}
    >
      <div className="  p-[1.875rem]">
        <div className="flex justify-between items-center ">
          {title}
          <div className="flex justify-between items-center gap-3 ">
            {icon}
            {!notopen && (
              <img
                onClick={handletoggle}
                src="/images/icons8pfeil-ausklappen3-1.png"
                className={`w-[30px] transition-all cursor-pointer ${
                  open && "rotate-90"
                }`}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
      {open && (
        <div className="px-[1.875rem] w-full">
          <hr className="bg-[#D8D8D8] text-[#D8D8D8] w-[100%] h-[2px]" />
        </div>
      )}
      <Collapse
        isOpened={open}
        className={`transition-all   animate-duration-1000 animate-dissolve`}
      >
        {children}
      </Collapse>
    </div>
  );
};

export default CollapseItem;
