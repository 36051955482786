import React, { forwardRef, useImperativeHandle, useReducer } from "react";
import { stripObjectKeep } from "../../../../utils/StripObject";
import ClipboardButtonsBar from "../../../Dashboard/kampaign/ClipboardButtonsBar";
import ClipboardButton from "../../../shared/ClipboardButton";
import Inputs from "../../shared/Input";
import Box from "../Layout/Box";
import CollapseItem from "../shared/CollapseItem";
import SwitchInput from "../shared/SwitchInput";

const TextConfig = forwardRef(({ landingPageData }, ref) => {
  const [inputs, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SET": {
        return action.payload.data;
      }
      case "SET_KEY": {
        return { ...state, [action.payload.key]: action.payload.value };
      }
    }
  }, stripObjectKeep(landingPageData, ["questionaireButtonText", "aufgrundDeinerAngaben", "negativeText", "wirLernenUnsAufDemText", "fallsDuDennochText", "inMaximal15Minuten", "vielenDankfurInteresse", "datenSchutzText", "whatsappButtonTextOne", "whatsappButtonTextTwo", "localReviewsHeader", "basicClosingText", "basicButtonType"]));

  useImperativeHandle(ref, () => ({
    async save() {
      console.log("textConfig.save()");
      return { landingPageData: inputs };
    },
  }));
  console.log(landingPageData.theme === "basic");
  return (
    <Box>
      <CollapseItem title="Passe die Texte Deiner Landingpage nach Deinen Bedürfnissen an">
        <div className="flex flex-col gap-5 mt-5">
          <Inputs
            value={inputs.whatsappButtonTextOne}
            onChange={(e) =>
              dispatch({
                type: "SET_KEY",
                payload: {
                  value: e.target.value,
                  key: "whatsappButtonTextOne",
                },
              })
            }
            label="Button Text"
          />
          {landingPageData.theme === "basic" && (
            <>
              {" "}
              <Inputs
                value={inputs.basicClosingText}
                onChange={(e) =>
                  dispatch({
                    type: "SET_KEY",
                    payload: {
                      value: e.target.value,
                      key: "basicClosingText",
                    },
                  })
                }
                label="Info Text"
              />{" "}
              <Inputs
                value={inputs.datenSchutzText}
                onChange={(e) =>
                  dispatch({
                    type: "SET_KEY",
                    payload: { value: e.target.value, key: "datenSchutzText" },
                  })
                }
                label="Datenschutztext"
              />
            </>
          )}
          {landingPageData.theme !== "basic" && (
            <>
              <Inputs
                value={inputs.localReviewsHeader}
                onChange={(e) =>
                  dispatch({
                    type: "SET_KEY",
                    payload: {
                      value: e.target.value,
                      key: "localReviewsHeader",
                    },
                  })
                }
                label="Überschrift Testimonials"
              />

              <ClipboardButtonsBar names={["Videolaenge"]} />

              <Inputs
                value={inputs.whatsappButtonTextTwo}
                onChange={(e) =>
                  dispatch({
                    type: "SET_KEY",
                    payload: {
                      value: e.target.value,
                      key: "whatsappButtonTextTwo",
                    },
                  })
                }
                label="Zweiter WhatsApp-Button"
              />
              <Inputs
                value={inputs.questionaireButtonText}
                onChange={(e) =>
                  dispatch({
                    type: "SET_KEY",
                    payload: {
                      value: e.target.value,
                      key: "questionaireButtonText",
                    },
                  })
                }
                label="Buttontext"
              />
            </>
          )}

          {landingPageData.theme !== "basic" && (
            <>
              <Inputs
                value={inputs.aufgrundDeinerAngaben}
                onChange={(e) =>
                  dispatch({
                    type: "SET_KEY",
                    payload: {
                      value: e.target.value,
                      key: "aufgrundDeinerAngaben",
                    },
                  })
                }
                label="Positivtext"
              />

              <Inputs
                value={inputs.negativeText}
                onChange={(e) =>
                  dispatch({
                    type: "SET_KEY",
                    payload: { value: e.target.value, key: "negativeText" },
                  })
                }
                label="Negativtext"
              />

              <Inputs
                value={inputs.wirLernenUnsAufDemText}
                onChange={(e) =>
                  dispatch({
                    type: "SET_KEY",
                    payload: {
                      value: e.target.value,
                      key: "wirLernenUnsAufDemText",
                    },
                  })
                }
                label="Weiteres Vorgehen (positiv) - erscheint nur, sofern das Abschlussvideo deaktiviert ist"
              />

              <Inputs
                value={inputs.fallsDuDennochText}
                onChange={(e) =>
                  dispatch({
                    type: "SET_KEY",
                    payload: {
                      value: e.target.value,
                      key: "fallsDuDennochText",
                    },
                  })
                }
                label="Weiteres Vorgehen (negativ)"
              />

              <Inputs
                value={inputs.datenSchutzText}
                onChange={(e) =>
                  dispatch({
                    type: "SET_KEY",
                    payload: { value: e.target.value, key: "datenSchutzText" },
                  })
                }
                label="Datenschutztext"
              />

              <Inputs
                value={inputs.vielenDankfurInteresse}
                onChange={(e) =>
                  dispatch({
                    type: "SET_KEY",
                    payload: {
                      value: e.target.value,
                      key: "vielenDankfurInteresse",
                    },
                  })
                }
                label="Danketext"
              />
            </>
          )}
        </div>
      </CollapseItem>
    </Box>
  );
});

export default TextConfig;
