import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";
class AnalyticService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/analytic`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });

    middleField(this.api, true);
  }

  getUserAnalytics(landingpage_id, range) {
    return this.api.post("/getUserAnalytics", {
      landingPageId: landingpage_id,
      range: range,
    });
  }

  getEGTAnalytics(range) {
    return this.api.post("/getEGTAnalytics", {
      range: range,
    });
  }

  getAdminUserAnalytics(range) {
    return this.api.post("/getAdminUserAnalytics", {
      range: range,
    });
  }
}

export default new AnalyticService();
