import { useState, useEffect } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdOutlineAdsClick } from "react-icons/md";
import { Button, Card, Segmented } from "antd";
import { aboTarif } from "../../../utils/divers";
import { Link } from "react-router-dom";

const StatisticsContent = ({
  range,
  setRange,
  analyticsData,
  loadingAnalytics,
  waActivated,
  closingVideoActivated,
  questionLength,
  user,
}) => {
  const [displayData, setDisplayData] = useState(analyticsData);

  useEffect(() => {
    if (!loadingAnalytics) {
      setDisplayData(analyticsData);
    }
  }, [analyticsData, loadingAnalytics, waActivated]);

  return (
    <div className="flex flex-col gap-4 items-center p-4 relative">
      {aboTarif(user) < 3 && (
        <>
          <div
            style={{ zIndex: 99999999999999999 }}
            className="absolute inset-0 bg-gray-100 backdrop-filter backdrop-blur-md"
          />
          <Card
            style={{ zIndex: 99999999999999999 }}
            className="absolute top-[220px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 text-center"
          >
            <h2 className="text-2xl font-bold mb-4">Upgrade benötigt</h2>
            <p className="mb-4">
              Um auf die Statistiken zugreifen zu können, musst du auf eine
              höhere Abonnementstufe upgraden.
            </p>
            <Link to="/dashboard/upgrade">
              <Button type="primary" className="bg-[#001D47] h-[40px] mr-2">
                Upgrade
              </Button>
            </Link>
          </Card>
        </>
      )}
      <h4 className="text-3xl">Statistiken</h4>
      <Segmented
        value={range}
        options={["Heute", "letzte 7 Tage", "letzte 30 Tage", "alle"]}
        default="alle"
        onChange={setRange}
      />
      <div className="flex flex-row gap-14 flex-nowrap text-nowrap">
        <ul className="flex flex-col flex-nowrap gap-4">
          <AiOutlineClockCircle className="w-8 h-8" />

          <li>
            <h1 className="sm:text-xl text-sm">Watchtime Kernvideo</h1>
            <h2>
              {loadingAnalytics
                ? displayData.watchtime_v1
                : displayData.watchtime_v1}
              %
            </h2>
          </li>
          {closingVideoActivated ? (
            <li>
              <h1 className="sm:text-xl text-sm">Watchtime Abschlussvideo</h1>
              <h2>
                {loadingAnalytics
                  ? displayData.watchtime_v2
                  : displayData.watchtime_v2}
                %
              </h2>
            </li>
          ) : null}
          {waActivated ? (
            <li>
              <h1 className="sm:text-xl text-sm">WA Sektion 1</h1>
              <h2>
                {loadingAnalytics
                  ? displayData.click_wa1
                  : displayData.click_wa1}
                %
              </h2>
            </li>
          ) : null}
          {waActivated ? (
            <li>
              <h1 className="sm:text-xl text-sm">WA Sektion 2</h1>
              <h2>
                {loadingAnalytics
                  ? displayData.click_wa3
                  : displayData.click_wa3}
                %
              </h2>
            </li>
          ) : null}
          {waActivated ? (
            <li>
              <h1 className="sm:text-xl text-sm">WA Formular</h1>
              <h2>
                {loadingAnalytics
                  ? displayData.click_wa2
                  : displayData.click_wa2}
                %
              </h2>
            </li>
          ) : null}
          <li>
            <h1 className="sm:text-xl text-sm">Aufrufe Kernvideo</h1>
            <h2>
              {loadingAnalytics ? displayData.play_v1 : displayData.play_v1}
            </h2>
          </li>
        </ul>
        <ul className="flex flex-col flex-nowrap gap-4">
          <MdOutlineAdsClick className="w-8 h-8" />
          <li>
            <h1 className="sm:text-xl text-sm">Fragen ansehen</h1>
            <h2>
              {loadingAnalytics ? displayData.click_qf : displayData.click_qf}%
            </h2>
          </li>
          {Array.from({ length: questionLength }, (_, index) => (
            <li key={index}>
              <h1 className="sm:text-xl text-sm">Frage {index + 1}</h1>
              <h2>
                {loadingAnalytics
                  ? displayData[`click_q${index + 1}`]
                  : displayData[`click_q${index + 1}`] || 0}
                %
              </h2>
            </li>
          ))}
          {closingVideoActivated ? (
            <li>
              <h1 className="sm:text-xl text-sm">Aufrufe Abschlussvideo</h1>
              <h2>
                {loadingAnalytics ? displayData.play_v2 : displayData.play_v2}
              </h2>
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default StatisticsContent;
