import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import PublicService from "../../../service/PublicService";
import {
  handleEnded,
  handlePause,
  handlePlay,
  sendEventEGT,
} from "../../../utils/analytics";
import ReviewSlider from "../MakeYouFit/ReviewSlider";
import ReponsiveYellowFace from "./ReponsiveYellowFace";
import ResponsiveBlueFace from "./ResponsiveBlueFace";

const Specialities = ({ reviews, untenOhne, obenOhne }) => {
  const videoRef = useRef();
  const [watchTime, setWatchTime] = useState(0);
  const [firstPlay, setFirstPlay] = useState(true);

  // Google Analaytics
  const googleAnalyticsHandler = (action, label) => {
    if (action === "play") {
      handlePlay(
        videoRef,
        setWatchTime,
        firstPlay,
        setFirstPlay,
        "none",
        "egtVid",
        false,
        true
      );
    } else if (action === "pause") {
      handlePause(
        videoRef,
        watchTime,
        setWatchTime,
        "none",
        "egtVid",
        false,
        true
      );
    } else if (action === "end") {
      handleEnded(
        videoRef,
        watchTime,
        setWatchTime,
        "none",
        "egtVid",
        false,
        true
      );
    } else if (action === "cta") {
      sendEventEGT({
        category: "button",
        action: "click-eg2",
        label: "eg2",
        value: 1,
      });
    }
  };
  return (
    <div className="mt-0 sm:mt-10 mb-10  relative z-10 w-[90%] primary:w-full xss:px-0 px-3    m-auto ">
      {!obenOhne && (
        <>
          <div className="primary:mt-[-100px] container max-w-6xl mx-auto hidden sm:block flex justify-center border-4 border-solid border-[#001d47] rounded-xl shadow-2xl overflow-hidden ">
            <video
              ref={videoRef}
              onPlay={() => googleAnalyticsHandler("play")}
              onPause={() => googleAnalyticsHandler("pause")}
              onEnded={() => googleAnalyticsHandler("end")}
              src="/Dein Loft Film - EinGuterTipp.mp4"
              controls
              preload="metadata"
              poster={"/thumb.png"}
            />
          </div>
          <Link
            to="/erstgesprach"
            className="mt-10 mb-10 flex-wrap primary:mx-10 flex primary:w-auto w-full justify-center gap-3 hidden sm:flex"
          >
            <button
              onClick={() => googleAnalyticsHandler("cta")}
              className="w-[283px] whitespace-nowrap bg-[#001D47] !text-[#ffffff]   animate-jump-in py-[10px] sm:mr-3  justify-center items-center flex text-center  secondary:py-[20px]   rounded-[50px] text-white font-[600] text-[20px] mt-3"
            >
              Beratung buchen
            </button>
          </Link>
        </>
      )}

      {/* MITTE */}

      {!untenOhne && (
        <>
          <Link
            to="/erstgesprach"
            className="mt-10 mb-10 flex-wrap primary:mx-10 flex primary:w-auto w-full justify-center gap-3 sm:hidden"
          >
            <button
              onClick={() => googleAnalyticsHandler("cta")}
              className="w-[283px] whitespace-nowrap bg-[#001D47] !text-[#ffffff]   animate-jump-in py-[10px] sm:mr-3  justify-center items-center flex text-center  secondary:py-[20px]   rounded-[50px] text-white font-[600] text-[20px] mt-3"
            >
              Beratung buchen
            </button>
          </Link>

          <div className=" text-center  secondary:text-primary font-bold  w-full mx-auto flex flex-col items-center flex-wrap sm:text-[26px] text-[20px]  whitespace-normal h-fit justify-center mt-[1rem] sm:mt-[8rem]">
            <span className="max-w-[800px] secondary:max-w-[1200px] text-center">
              Warum Kunden keine Lust auf klassisches Empfehlungsmarketing haben
              und es mit
            </span>
            <span className="flex items-center justify-center text-center mx-auto">
              unserer Lösung{" "}
              <img
                src="/images/logo.png"
                className="mx-3 md:w-[50px] w-[25px]"
                alt=""
                srcSet=""
                width="50"
              />{" "}
              werden.
            </span>
          </div>
          <div className=" flex-col justify-center font-bold items-center gap-10 my-10   ">
            <ReponsiveYellowFace />
            <ResponsiveBlueFace />
          </div>
        </>
      )}
    </div>
  );
};

export default Specialities;
