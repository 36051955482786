import { useState, useEffect, useCallback } from "react";

const STORAGE_KEY = "gradientHistory";
const MAX_HISTORY_LENGTH = 5;

// Custom Event für die Synchronisation
const GRADIENT_UPDATE_EVENT = "gradientHistoryUpdate";

// Event emitter für die interne Kommunikation
const emitGradientUpdate = (gradientHistory) => {
  window.dispatchEvent(
    new CustomEvent(GRADIENT_UPDATE_EVENT, { detail: gradientHistory })
  );
};

export const useSharedGradientHistory = () => {
  const [sharedGradientHistory, setSharedGradientHistory] = useState(() => {
    try {
      const savedHistory = localStorage.getItem(STORAGE_KEY);
      return savedHistory ? JSON.parse(savedHistory) : [];
    } catch (error) {
      console.error("Error loading gradient history:", error);
      return [];
    }
  });

  // Listener für interne Updates
  useEffect(() => {
    const handleInternalUpdate = (event) => {
      setSharedGradientHistory(event.detail);
    };

    // Listener für localStorage Updates (andere Tabs)
    const handleStorageChange = (e) => {
      if (e.key === STORAGE_KEY) {
        try {
          const newHistory = e.newValue ? JSON.parse(e.newValue) : [];
          setSharedGradientHistory(newHistory);
          // Propagiere Änderung an andere Komponenten
          emitGradientUpdate(newHistory);
        } catch (error) {
          console.error("Error parsing gradient history:", error);
        }
      }
    };

    window.addEventListener(GRADIENT_UPDATE_EVENT, handleInternalUpdate);
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener(GRADIENT_UPDATE_EVENT, handleInternalUpdate);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const updateGradientHistory = useCallback((newGradient) => {
    setSharedGradientHistory((prevHistory) => {
      const newHistory = prevHistory.filter((g) => g !== newGradient);
      newHistory.unshift(newGradient);
      const updatedHistory = newHistory.slice(0, MAX_HISTORY_LENGTH);

      try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedHistory));
        // Informiere andere Komponenten über die Änderung
        emitGradientUpdate(updatedHistory);
      } catch (error) {
        console.error("Error saving gradient history:", error);
      }

      return updatedHistory;
    });
  }, []);

  const clearGradientHistory = useCallback(() => {
    setSharedGradientHistory([]);
    try {
      localStorage.removeItem(STORAGE_KEY);
      emitGradientUpdate([]);
    } catch (error) {
      console.error("Error clearing gradient history:", error);
    }
  }, []);

  return {
    gradientHistory: sharedGradientHistory,
    updateGradientHistory,
    clearGradientHistory,
  };
};
