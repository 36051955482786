import React from "react";

const TypeButton = ({ type, text, onClick, className }) => {
  let style = "";
  switch (type) {
    case "finance":
    case "individual":
      style =
        "bg-[linear-gradient(152deg,_#00DEF7_0%,_#0084F7_49.48%,_#001D47_100%)] shadow-[0px_0px_26.711999893188477px_0px_#06DEF8] rounded-full";
      break;
    case "stormgas":
      style =
        "bg-[linear-gradient(152deg,#DBEF5D_0%,#B8D01D_49.48%,#8DA10B_100%)] shadow-[0px_0px_26.711999893188477px_0px_#DBEF5D] rounded-0";
      break;
    case "property":
      style = "bg-darkslateblue rounded-sm";
      break;
    case "basic":
      style =
        "md:!text-sm bg-[linear-gradient(152deg,_#00DEF7_0%,_#0084F7_49.48%,_#001D47_100%)] shadow-[0px_0px_26.711999893188477px_0px_#06DEF8] rounded-full";
      break;
  }

  return (
    <div className={className}>
      <button
        onClick={onClick}
        className={`${style} 
        mx-auto  
        gap-4 
        items-center 
        justify-center 
        font-semibold 
       
        text-white 
        flex 
        
        text-sm
        py-4
        px-7

        md:text-xl
        md:py-4
        md:px-10 

        `}
      >
        {text}
      </button>
    </div>
  );
};

export default TypeButton;
