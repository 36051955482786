import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { MainLogo } from "../../../assets/Svgs/GUTERTIPP";
import MovingHearts from "../../MovingHearts/MovingHearts";
import LoadingScreen from "../../_NEW/LandingPage/loadingScreen/LoadingScreen";

const Erstgesprach = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.fbq("track", "ViewContent");
    setTimeout(() => setLoading(false), 2000);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div>
      {loading && (
        <div className="z-[2] w-screen h-screen bg-[#ffffff] fixed left-0 right-0 top-0 bottom-0">
          <LoadingScreen />
        </div>
      )}
      {!loading && (
        <div className="z-[2] w-screen h-screen bg-[#001D47] fixed left-0 right-0 top-0 bottom-0"></div>
      )}
      <div className="flex justify-center items-center flex-col text-center h-full relative z-40">
        <style>
          {`
            #root{
                position:relative;
                height:100vh;
                display:flex;
                justify-content:center;
                align-items:center;
                overflow:hidden;
                
            }
            `}
        </style>
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/saschaemmerich/egt-beratung?primary_color=001d47"
          style={{
            minWidth: "90vw",
            height: "90vh",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Erstgesprach;
