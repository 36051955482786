import React from "react";

import { Rate } from "antd";
import { EingutterHeart } from "../../utils/EinguterCustomReactIcon";

const Stars = ({ rating, setRating }) => {
  const HeartStar = ({ index }) => {
    return <EingutterHeart fill={!(index + 1 <= rating) ? "gray" : ""} />;
  };

  return (
    <div className="flex items-center gap-3 ">
      <Rate
        value={rating}
        character={HeartStar}
        onChange={(e) => {
          if (setRating) setRating(e);
        }}
      />
    </div>
  );
};

export default Stars;
