import { Alert, Button, Checkbox, message, Modal, Popconfirm } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { login } from "../../redux/auth/actions";
import { selectUser } from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import CrudService from "../../service/CrudService";
import PromotionsLinkService from "../../service/PromotionsLinkService";
import UserService from "../../service/UserService";
import CollapseItem from "../Dashboard/kampaign/CollapseItem";
import FloatLabel from "../FloatingLabel";
import { AddLandingPageModal } from "./AddLandingPageModal";
import { notiificationoconfig, Pramienkeys } from "./Data";
import { Heading } from "./Heading";

const Einstellungen = ({ title }) => {
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [landingPages, setLandingPages] = useState([]);
  const [editingLP, setEditingLP] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(false);

  const { user } = useSelector(selectUser);

  const fetchLandingPages = useCallback(async () => {
    if (!user) return;

    const res = await CrudService.search("LinkKonfig", 100000, 1, {
      filters: { user_id: user._id },
      populate: "landingPage",
    });
    setLandingPages(res.data.items);
  }, [user]);

  useEffect(() => {
    fetchLandingPages();
  }, [fetchLandingPages]);

  const getUser = async (setdata) => {
    const { data } = await UserService.me();
    store.dispatch(login(data));
    if (setdata) setUserSettings(data.user);
  };

  const [userSettings, setUserSettings] = useState({});
  React.useEffect(() => {
    title("Einstellungen");
    setTimeout(() => title("Einstellungen"), 200);
    getUser(true);
    return () => {
      UserService.updateUserInfo({ ...userSettings });
    };
  }, [title]);
  const handleChange = (key, subkey, subsubkey, value, pattern) => {
    if (pattern && !pattern?.test(value)) {
      return;
    }
    setUserSettings((prev) => ({
      ...prev,
      [key]: {
        ...prev?.[key],
        [subkey]: { ...prev?.[key]?.[subkey], [subsubkey]: value },
      },
    }));
  };
  const handleChangePramien = (key, subkey, value, pattern) => {
    if (pattern && !pattern?.test(value)) {
      return;
    }
    setUserSettings((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [subkey]: value,
      },
    }));
  };

  const handleSave = async () => {
    await UserService.updateUserInfo({ ...userSettings }).then(() => {
      getUser(true);
      message.success("Erfolgreich gespeichert");
    });
  };
  return (
    <div>
      <CollapseItem
        defaultOpen={true}
        title={
          <div className="text-xl font-bold">Erinnerungen konfigurieren:</div>
        }
      >
        <div className="flex flex-col gap-7 mt-5 text-sm">
          {Object.keys(notiificationoconfig)?.map((e) => {
            const textParts = notiificationoconfig[e].split("...");

            return (
              <div className="flex  gap-5 items-center">
                <div className="flex gap-2 items-center">
                  <div className="text-center relative w-[30px]">
                    <p className="absolute -top-4 mx-auto left-0 right-0 ">
                      App
                    </p>
                    <Checkbox
                      className="scale-150 bg-transparent mt-2"
                      checked={
                        userSettings?.notificationConfiguration?.[e]?.app
                      }
                      onChange={(ce) =>
                        handleChange(
                          "notificationConfiguration",
                          e,
                          "app",
                          ce.target.checked
                        )
                      }
                    />
                  </div>
                  <div className="text-center relative w-[30px]">
                    <p className="absolute -top-4 mx-auto left-0 right-0">
                      Mail
                    </p>
                    <Checkbox
                      className="scale-150 bg-transparent mt-2"
                      checked={
                        userSettings?.notificationConfiguration?.[e]?.email
                      }
                      onChange={(ce) =>
                        handleChange(
                          "notificationConfiguration",
                          e,
                          "email",
                          ce.target.checked
                        )
                      }
                    />
                  </div>
                </div>
                <div className="flex  gap-5 items-center">
                  {textParts[0]}

                  {!!textParts[1] && (
                    <FloatLabel
                      className={
                        "border border-[#d8d8d8] rounded-3xs h-12 w-16  p-2"
                      }
                      keepitfocused
                      label={"Dauer"}
                    >
                      <input
                        type="text"
                        value={
                          userSettings?.notificationConfiguration?.[e]?.days
                        }
                        onChange={(ce) =>
                          handleChange(
                            "notificationConfiguration",
                            e,
                            "days",
                            ce.target.value,
                            ce.target.value.length > 0 ? /^[0-9]+$/g : null
                          )
                        }
                        className="outline-none border-none appearance-none h-full w-full bg-transparent"
                      />
                    </FloatLabel>
                  )}
                  {textParts[1] ?? ""}
                </div>
              </div>
            );
          })}
        </div>

        <Button
          className="h-[60px] w-full my-5 text-xl rounded-3xs"
          type="primary"
          onClick={() => handleSave(true)}
        >
          Speichern
        </Button>
      </CollapseItem>

      <CollapseItem
        spaceOben
        title={
          <div className="text-md md:text-xl font-semibold">
            Verwaltung Empfehlungsportal
          </div>
        }
        defaultOpen={true}
      >
        <div className="p-8 flex flex-col gap-8 item">
          <div className=" flex flex-col gap-[30px] self-stretch">
            <div className="flex flex-col items-start gap-5">
              <Heading
                size="desktop_label_bold"
                as="h5"
                className="text-[20px] font-semibold text-[#000000]"
              >
                Links konfigurieren
              </Heading>
              <div className="self-stretch rounded-[16px] bg-[#ffffff] p-[30px] sm:p-5">
                <div className="flex flex-col gap-[30px]">
                  {landingPages?.map?.((x) => (
                    <div
                      key={x._id}
                      className="flex flex-1 items-center justify-between gap-5 "
                    >
                      <div className="flex justify-between w-[350px]">
                        <div className="flex items-center gap-2.5">
                          <img
                            src={x.theme.icon}
                            alt=" "
                            className="h-[20px]"
                          />
                          <Heading
                            size="desktop_label_semibold"
                            as="h6"
                            className="text-[16px] font-semibold text-[#000000]"
                          >
                            {x?.theme?.label}
                          </Heading>
                        </div>
                        <div className="flex flex-col items-end justify-center">
                          <Heading
                            as="p"
                            className="text-[14px] font-medium text-[#868686]"
                          >
                            Landingpage
                          </Heading>
                          <Heading
                            size="desktop_label_semibold"
                            as="h6"
                            className="text-[16px] font-semibold text-[#000000] max-w-[200px] truncate"
                          >
                            {x?.landingPage?.name}
                          </Heading>
                        </div>
                      </div>
                      <div className="flex gap-2.5">
                        {x?.disabled ? (
                          <img
                            src="/images/img_info_red.svg"
                            alt="Info Icon"
                            className="h-[38px] rounded-lg cursor-pointer bg-[#ff0000]"
                            onClick={async () => {
                              await CrudService.update("LinkKonfig", x._id, {
                                disabled: false,
                              });
                              fetchLandingPages();
                            }}
                          />
                        ) : (
                          <img
                            src="/images/img_info_green.svg"
                            alt="Info Icon"
                            className="h-[38px] rounded-lg cursor-pointer"
                            onClick={() =>
                              setConfirmation({ action: "disable", id: x._id })
                            }
                          />
                        )}

                        <div
                          className="flex h-[38px] w-[38px] items-center justify-center rounded-lg border border-solid border-[#d8d8d8] bg-[#ffffff] px-2.5 cursor-pointer"
                          onClick={() => {
                            setEditingLP(x);
                            setOpenAddNewModal(true);
                          }}
                        >
                          <img src="/images/img_edit.svg" />
                        </div>

                        <img
                          onClick={() =>
                            setConfirmation({ action: "delete", id: x._id })
                          }
                          src="/images/img_info_white_0.svg"
                          alt="White Info Icon"
                          className="h-[38px] rounded-lg cursor-pointer"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Button
              onClick={() => {
                setOpenAddNewModal(true);
                setEditingLP(null);
              }}
              className="flex h-[70px] flex-row items-center justify-center self-stretch rounded-[10px] bg-[#0084f7] px-[34px] text-center text-[20px] font-semibold text-[#ffffff] sm:px-5"
            >
              Neue Landingpage freigeben
            </Button>
          </div>
          {/* <div className="mt-5 flex flex-col items-start justify-center gap-[26px] self-stretch">
            <Heading
              size="desktop_label_bold"
              as="h5"
              className="text-[20px] font-semibold text-[#000000]"
            >
              Bonusprämien konfigurieren
            </Heading>
            <div className="self-stretch rounded-[16px] bg-[#ffffff] p-[30px] sm:p-5">
              <div className="flex flex-col gap-[30px]">
                <div className="flex flex-1 items-center justify-between gap-5 ">
                  <div className="flex gap-12">
                    <div className="flex items-center justify-between gap-5">
                      <img
                        src="/images/img_111851_sp880_ai.png"
                        alt="Prize Icon"
                        className="h-[50px] object-cover"
                      />
                      <div className="flex flex-col items-center justify-center">
                        <Heading
                          as="p"
                          className="text-[14px] font-medium text-[#868686]"
                        >
                          Prämienname
                        </Heading>
                        <Heading
                          size="desktop_label_semibold"
                          as="h6"
                          className="text-[16px] font-semibold text-[#000000]"
                        >
                          Airpods Pro
                        </Heading>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                      <Heading
                        as="p"
                        className="text-[14px] whitespace-nowrap font-medium text-[#868686]"
                      >
                        Anzahl Empfehlungen
                      </Heading>
                      <Heading
                        size="desktop_label_semibold"
                        as="h6"
                        className="text-[16px] font-semibold text-[#000000]"
                      >
                        3
                      </Heading>
                    </div>
                  </div>
                  <div className="flex gap-2.5">
                    <img
                      src="/images/img_info.svg"
                      alt="Prize Info Icon"
                      className="h-[38px] rounded-lg cursor-pointer"
                    />
                    <div className="flex h-[38px] w-[38px] items-center justify-center rounded-lg border border-solid border-[#d8d8d8] bg-[#ffffff] px-2.5 cursor-pointer">
                      <img src="/images/img_edit.svg" />
                    </div>
                    <img
                      src="/images/img_info_white_0.svg"
                      alt="White Prize Info Icon"
                      className="h-[38px] rounded-lg cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
            <Button className="flex h-[70px] flex-row items-center justify-center self-stretch rounded-[10px] bg-[#0084f7] px-[34px] text-center text-[20px] font-semibold text-[#ffffff] sm:px-5">
              Neue Bonusprämie erstellen
            </Button>
          </div> */}
        </div>
      </CollapseItem>

      <div className="my-10">
        <CollapseItem
          defaultOpen={true}
          title={
            <div className="text-xl font-semibold">
              Standardkonfiguration für Prämiensystem:
            </div>
          }
        >
          <div className="flex flex-col gap-7 mt-5 text-sm">
            <Alert
              type="info"
              message="Diese Einstellungen werden für jede neu angelegte Landingpage übernommen, können aber dort jederzeit angepasst werden."
            />
            {Object.keys(Pramienkeys)?.map((e, i) => {
              return (
                <FloatLabel
                  label={Pramienkeys[e]}
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"xl:flex-grow-0 flex-grow"}
                  keepitfocused
                  key={i}
                >
                  <input
                    type="text"
                    className="border-[1px] border-[#d8d8d8] !shadow-none font-semibold !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full "
                    value={
                      (userSettings?.pramienConfiguration?.[e] || 0) + " Euro"
                    }
                    onChange={(ce) =>
                      handleChangePramien(
                        "pramienConfiguration",
                        e,
                        ce.target.value.replace(" Euro", ""),
                        ce.target.value.replace(" Euro", "").length > 0
                          ? /^[0-9]+$/g
                          : null
                      )
                    }
                  />
                </FloatLabel>
              );
            })}
          </div>

          <Button
            className="h-[60px] w-full my-5 text-xl rounded-3xs"
            type="primary"
            onClick={() => handleSave(true)}
          >
            Speichern
          </Button>
        </CollapseItem>
      </div>

      <AddLandingPageModal
        open={openAddNewModal}
        onOpenChange={setOpenAddNewModal}
        handleRefreshPages={fetchLandingPages}
        editingLP={editingLP}
        setEditingLP={setEditingLP}
        landingPageThemes={landingPages}
      />

      <Modal
        open={confirmation}
        onCancel={() => {
          setConfirmation(false);
          setCheckboxValue(false);
        }}
        title="ACHTUNG!"
        footer={[]}
      >
        <div>
          <p className="my-1">
            Mit der Deaktivierung dieser Landingpage im Empfehlungsportal,
            werden auch alle zugehörigen Links für das Portal deaktiviert.
            Sprich, deine Promoter sehen den Link zu dieser Seite nicht mehr.
          </p>
          <div className="flex items-start justify-start tertiary:gap-4 gap-1 mt-4 mb-4">
            <Checkbox
              checked={checkboxValue}
              onChange={(e) => setCheckboxValue(e.target.checked)}
            />
            <p className="text-ellipsis text-start hyphens-auto ">
              Ich bestätige, dass ich den Hinweis zur Kenntnis genommen habe und
              mir der Folgen bewusst bin
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between w-full gap-1 sm:flex-row sm:flex-nowrap">
          <Button
            className="bg-redattendtion rounded-[10px] text-white h-[46px] w-[250px]"
            onClick={async () => {
              if (!checkboxValue)
                return message.info(
                  "Bitte bestätige, dass du den Hinweis zur Kenntnis genommen hast"
                );
              setConfirmation(false);
              setCheckboxValue(false);

              await PromotionsLinkService.disableLandingPage(confirmation.id);

              if (confirmation.action === "delete") {
                await CrudService.delete("LinkKonfig", confirmation.id);
                fetchLandingPages();
              } else {
                await CrudService.update("LinkKonfig", confirmation.id, {
                  disabled: true,
                });
                fetchLandingPages();
              }
            }}
          >
            Deaktivieren
          </Button>
          <Button
            type="primary"
            className="h-[46px] w-[250px] rounded-[10px]"
            onClick={(e) => {
              setConfirmation(false);
              setCheckboxValue(false);
            }}
          >
            Abbrechen
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Einstellungen;
