import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import GradientPicker from "react-best-gradient-color-picker";
import Draggable from "react-draggable";
import { useSharedGradientHistory } from "./useGradientHistory";
// Importieren von Quill-Modulen
import ImageResize from "quill-image-resize-module-react";

const Delta = Quill.import("delta");
const Parchment = Quill.import("parchment");
// Clipboard-Matcher-Funktion definieren
// function preserveDivBackground(node, delta) {
//   if (node.tagName === "DIV") {
//     const attributes = {};
//     if (node.style.background) {
//       attributes.background = node.style.background;
//     }
//     if (node.style.padding) {
//       attributes.padding = node.style.padding;
//     }
//     if (node.style.borderRadius) {
//       attributes.borderRadius = node.style.borderRadius;
//     }
//     if (node.style.margin) {
//       attributes.margin = node.style.margin;
//     }

//     // Anwenden der Attribute auf jede Zeile
//     delta.ops = delta.ops.map((op) => {
//       if (
//         op.insert &&
//         typeof op.insert === "string" &&
//         op.insert.includes("\n")
//       ) {
//         op.attributes = { ...op.attributes, ...attributes };
//       }
//       return op;
//     });
//   }
//   return delta;
//}

// Registrieren der Style-Attributoren
const BackgroundStyle = new Parchment.Attributor.Style(
  "background",
  "background",
  {
    scope: Parchment.Scope.BLOCK,
  }
);
const PaddingStyle = new Parchment.Attributor.Style("padding", "padding", {
  scope: Parchment.Scope.BLOCK,
});
const BorderRadiusStyle = new Parchment.Attributor.Style(
  "borderRadius",
  "border-radius",
  {
    scope: Parchment.Scope.BLOCK,
  }
);
const MarginStyle = new Parchment.Attributor.Style("margin", "margin", {
  scope: Parchment.Scope.BLOCK,
});

Quill.register(BackgroundStyle, true);
Quill.register(PaddingStyle, true);
Quill.register(BorderRadiusStyle, true);
Quill.register(MarginStyle, true);

Quill.register("modules/imageResize", ImageResize);

const sizes = {
  "12px": "12px",
  "16px": "16px",
  "36px": "36px",
  "20px": "20px",
  "24px": "24px",
};

const Block = Quill.import("blots/block");
class CustomParagraphBlot extends Block {
  static create(value) {
    const node = super.create(value);
    node.classList.add("custom-spacing");
    return node;
  }

  static formats(node) {
    return node.classList.contains("custom-spacing") ? true : false;
  }
}

CustomParagraphBlot.blotName = "customParagraph";
CustomParagraphBlot.tagName = "p";

Quill.register(CustomParagraphBlot);

// Definieren des benutzerdefinierten Image-Formats
const ImageFormat = Quill.import("formats/image");

class CustomImage extends ImageFormat {
  static create(value) {
    let node = super.create();
    let src = value;
    if (typeof value === "object") {
      src = value.src;
      if (value.width) node.setAttribute("width", value.width);
      if (value.height) node.setAttribute("height", value.height);
    }
    node.setAttribute("src", src);
    return node;
  }

  static formats(domNode) {
    let formats = {};
    if (domNode.hasAttribute("src")) {
      formats.src = domNode.getAttribute("src");
    }
    if (domNode.hasAttribute("width")) {
      formats.width = domNode.getAttribute("width");
    }
    if (domNode.hasAttribute("height")) {
      formats.height = domNode.getAttribute("height");
    }
    return formats;
  }

  format(name, value) {
    if (name === "width" || name === "height") {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else if (name === "src") {
      if (value) {
        this.domNode.setAttribute("src", value);
      } else {
        this.domNode.removeAttribute("src");
      }
    } else {
      super.format(name, value);
    }
  }
}

CustomImage.blotName = "image";
CustomImage.tagName = "img";
Quill.register(CustomImage, true);

// Registrieren des Size-Formats und Beschränken der Whitelist
const Size = Quill.import("formats/size");
Size.whitelist = Object.keys(sizes);
Quill.register(Size, true);

let Inline = Quill.import("blots/inline");
class GradientBlot extends Inline {
  static create(value) {
    let node = super.create();
    node.style.background = value;
    node.style.padding = "2px 10px";
    node.style.borderRadius = "15px";
    node.style.webkitBoxDecorationBreak = "clone";
    node.style.boxDecorationBreak = "clone";
    node.style.lineHeight = "1.8";
    return node;
  }

  static formats(node) {
    return node.style.background;
  }

  format(name, value) {
    if (name === "gradient" && value) {
      this.domNode.style.background = value;
      this.domNode.style.padding = "2px 10px";
      this.domNode.style.borderRadius = "15px";
      this.domNode.webkitBoxDecorationBreak = "clone";
      this.domNode.boxDecorationBreak = "clone";
      this.domNode.lineHeight = "1.8";
    } else if (name === "gradient") {
      this.domNode.style.background = "";
      this.domNode.style.marginBottom = "";
      this.domNode.style.padding = "";
      this.domNode.style.borderRadius = "";
      this.domNode.webkitBoxDecorationBreak = "clone";
      this.domNode.boxDecorationBreak = "clone";
      this.domNode.lineHeight = "1.8";
    } else {
      super.format(name, value);
    }
  }
}

GradientBlot.blotName = "gradient";
GradientBlot.tagName = "span";
Quill.register(GradientBlot, true);

class GradientLineBlot extends Block {
  static create(value) {
    console.log("GradientLineBlot create called with value:", value);
    const node = super.create(value);

    // Basis-Styles
    const baseStyles = {
      background: value.gradient,
      padding: "10px 15px",
      margin: "10px 0",
      borderRadius: "8px",
      width: value.width || "100%",
      display: "block",
    };

    // Ausrichtung basierend auf boxAlignment
    if (value.width !== "100%") {
      switch (value.alignment) {
        case "center":
          baseStyles.marginLeft = "auto";
          baseStyles.marginRight = "auto";
          break;
        case "right":
          baseStyles.marginLeft = "auto";
          baseStyles.marginRight = "0";
          break;
        case "left":
          baseStyles.marginLeft = "0";
          baseStyles.marginRight = "auto";
          break;
      }
    }

    Object.assign(node.style, baseStyles);

    if (value.hasNextLine) {
      node.classList.add("custom-spacing");
    }

    return node;
  }

  static formats(node) {
    const formats = {
      gradient: node.style.background || false,
      width: node.style.width || "100%",
      alignment: "center",
    };

    if (node.style.marginLeft === "auto" && node.style.marginRight === "auto") {
      formats.alignment = "center";
    } else if (node.style.marginLeft === "auto") {
      formats.alignment = "right";
    } else if (node.style.marginRight === "auto") {
      formats.alignment = "left";
    }

    if (node.classList.contains("custom-spacing")) {
      formats.hasNextLine = true;
    }

    return formats;
  }
}

GradientLineBlot.blotName = "gradientLine";
GradientLineBlot.tagName = "div";

Quill.register(GradientLineBlot);

const Container = Quill.import("blots/container");

class GradientWrapperBlot extends Container {
  static create(value) {
    const node = super.create(value);
    node.classList.add("gradient-wrapper");
    node.style.width = "fit-content";
    node.style.display = "block";

    // Alignment über margin
    switch (value.alignment) {
      case "center":
        node.style.marginLeft = "auto";
        node.style.marginRight = "auto";
        break;
      case "right":
        node.style.marginLeft = "auto";
        node.style.marginRight = "0";
        break;
      case "left":
        node.style.marginLeft = "0";
        node.style.marginRight = "auto";
        break;
    }

    return node;
  }

  static formats(domNode) {
    return {
      alignment:
        domNode.style.marginLeft === "auto" &&
        domNode.style.marginRight === "auto"
          ? "center"
          : domNode.style.marginLeft === "auto"
          ? "right"
          : "left",
    };
  }
}

GradientWrapperBlot.blotName = "gradient-wrapper";
GradientWrapperBlot.tagName = "div";
GradientWrapperBlot.scope = Quill.import("parchment").Scope.BLOCK_BLOT;
Quill.register(GradientWrapperBlot);
const BlockEmbed = Quill.import("blots/block/embed");

class GradientWrapperPasteBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.classList.add("gradient-wrapper");
    node.style.width = "fit-content";
    node.style.display = "block";

    if (value.content) {
      node.innerHTML = value.content;
    }

    switch (value.alignment) {
      case "center":
        node.style.marginLeft = "auto";
        node.style.marginRight = "auto";
        break;
      case "right":
        node.style.marginLeft = "auto";
        node.style.marginRight = "0";
        break;
      default:
        node.style.marginLeft = "0";
        node.style.marginRight = "auto";
    }

    return node;
  }

  static value(node) {
    return {
      content: node.innerHTML,
      alignment:
        node.style.marginLeft === "auto" && node.style.marginRight === "auto"
          ? "center"
          : node.style.marginLeft === "auto"
          ? "right"
          : "left",
    };
  }
}

GradientWrapperPasteBlot.blotName = "gradient-wrapper-paste";
GradientWrapperPasteBlot.tagName = "div";

// Dann im Matcher:
// function preserveGradientWrapper(node, delta) {
//   if (node.tagName === "DIV" && node.style.width === "fit-content") {
//     return new Delta().insert({
//       "gradient-wrapper-paste": {
//         content: node.innerHTML,
//         alignment:
//           node.style.marginLeft === "auto" && node.style.marginRight === "auto"
//             ? "center"
//             : node.style.marginLeft === "auto"
//             ? "right"
//             : "left",
//       },
//     });
//   }
//   return delta;
// }
class GradientTextBlot extends Inline {
  static create(value) {
    const node = super.create();
    node.style.webkitBoxDecorationBreak = "clone";
    node.style.boxDecorationBreak = "clone";
    node.style.lineHeight = "1.6";
    return node;
  }

  static formats(node) {
    return (
      node.style.webkitBoxDecorationBreak === "clone" ||
      node.style.boxDecorationBreak === "clone"
    );
  }

  format(name, value) {
    if (name === "gradientText") {
      if (value) {
        this.domNode.style.webkitBoxDecorationBreak = "clone";
        this.domNode.style.boxDecorationBreak = "clone";
        this.domNode.style.lineHeight = "1.6";
      } else {
        this.domNode.style.webkitBoxDecorationBreak = "";
        this.domNode.style.boxDecorationBreak = "";
        this.domNode.style.lineHeight = "";
      }
    } else {
      super.format(name, value);
    }
  }
}

GradientTextBlot.blotName = "gradientText";
GradientTextBlot.tagName = "span";

Quill.register(GradientTextBlot);

const Editor = ({
  quillattributes,
  value = "",
  onChange = () => {},
  label = "",
  variables,
  options = {},
}) => {
  const [selectedGradient, setSelectedGradient] = useState("rgb(239, 26, 26)");
  const [showGradientPicker, setShowGradientPicker] = useState(false);
  const editorRef = useRef(null);
  const [bgWidth, setbgWidth] = useState("text");
  const [boxAlignment, setBoxAlignment] = useState("left");

  const { gradientHistory, updateGradientHistory } = useSharedGradientHistory();

  useEffect(() => {
    localStorage.setItem("gradientHistory", JSON.stringify(gradientHistory));
  }, [gradientHistory]);

  /**
   * Funktion zum Einfügen von Bildern mit Standardgröße
   */
  const insertImageWithDefaultSize = useCallback(() => {
    const quill = editorRef.current.getEditor();
    const range = quill.getSelection();
    if (!range) return;

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imageUrl = e.target.result;
          quill.insertEmbed(range.index, "image", {
            src: imageUrl,
            width: "200px",
            height: "200px",
          });
          quill.setSelection(range.index + 1);
        };
        reader.readAsDataURL(file);
      }
    };
  }, []);

  /**
   * Handler zum Anzeigen des Gradient-Pickers
   */
  const handleGradient = useCallback(() => {
    setShowGradientPicker(true);
  }, []);

  const [initialized, setInitialized] = useState(false);

  /**
   * Funktion zum Anwenden des Gradients auf die ausgewählte Textstelle
   */
  const applyGradient = useCallback(() => {
    const quill = editorRef.current.getEditor();
    const range = quill.getSelection();

    if (range && range.length > 0) {
      updateGradientHistory(selectedGradient);
      const [startLine] = quill.getLine(range.index);
      const [endLine] = quill.getLine(range.index + range.length);

      const isMultiLine = startLine !== endLine;

      const isCompleteLine = (() => {
        if (isMultiLine) {
          console.log("IsMultiLine true, returning true");
          return true;
        }

        const lineStart = quill.getIndex(startLine);
        const lineLength = startLine.length();
        const selectionStart = range.index;
        const selectionEnd = range.index + range.length;
        const lineEnd = lineStart + lineLength;

        const isComplete =
          selectionStart <= lineStart && selectionEnd >= lineEnd - 1;

        console.log("Conditions:", {
          startCondition: selectionStart <= lineStart,
          endCondition: selectionEnd >= lineEnd - 1,
          isComplete,
        });

        return isComplete;
      })();

      const isInline = !isMultiLine && !isCompleteLine;

      if (isInline) {
        const [line] = quill.getLine(range.index);

        setTimeout(() => {
          quill.formatText(range.index, range.length, {
            gradient: selectedGradient,
          });
        }, 0);

        if (line && line.domNode) {
          const nextLine = quill.getLine(
            quill.getIndex(line) + line.length() + 1
          )[0];
          if (nextLine) {
            const lineIndex = quill.getIndex(line);
            quill.formatLine(lineIndex, 1, "customParagraph", true);
          }
        }
      } else if (isCompleteLine && !isMultiLine) {
        const [line] = quill.getLine(range.index);
        const lineIndex = quill.getIndex(line);

        const nextLineIndex = lineIndex + line.length();
        const hasNextLine = nextLineIndex < quill.getLength();

        const wrapperNode = document.createElement("div");
        wrapperNode.classList.add("gradient-wrapper");
        wrapperNode.style.width = bgWidth === "box" ? "100%" : "fit-content";
        wrapperNode.style.display = "block";

        switch (boxAlignment) {
          case "center":
            wrapperNode.style.marginLeft = "auto";
            wrapperNode.style.marginRight = "auto";
            break;
          case "right":
            wrapperNode.style.marginLeft = "auto";
            wrapperNode.style.marginRight = "0";
            break;
          case "left":
            wrapperNode.style.marginLeft = "0";
            wrapperNode.style.marginRight = "auto";
            break;
        }

        if (line.domNode) {
          Array.from(line.domNode.classList).forEach((className) => {
            if (
              className.startsWith("gradient-") ||
              className.startsWith("solid-")
            ) {
              line.domNode.classList.remove(className);
            }
          });

          const baseStyles = {
            background: selectedGradient,
            padding: "10px 15px",
            margin: "10px 0",
            borderRadius: "8px",
            display: "block",
            width: "100%",
          };

          Object.assign(line.domNode.style, baseStyles);

          line.domNode.parentNode.insertBefore(wrapperNode, line.domNode);
          wrapperNode.appendChild(line.domNode);

          quill.update();
        }

        quill.setSelection(lineIndex + line.length(), 0);
      } else {
        const [startBlock] = quill.getLine(range.index);
        const [endBlock] = quill.getLine(range.index + range.length);

        const startIndex = quill.getIndex(startBlock);
        const endIndex = quill.getIndex(endBlock) + endBlock.length();
        const linesInRange = quill.getLines(startIndex, endIndex - startIndex);
        const hasNextLine = endIndex < quill.getLength();

        const wrapperNode = document.createElement("div");
        wrapperNode.classList.add("gradient-wrapper");
        wrapperNode.style.width = bgWidth === "box" ? "100%" : "fit-content";
        wrapperNode.style.display = "block";
        switch (boxAlignment) {
          case "center":
            wrapperNode.style.marginLeft = "auto";
            wrapperNode.style.marginRight = "auto";
            break;
          case "right":
            wrapperNode.style.marginLeft = "auto";
            wrapperNode.style.marginRight = "0";
            break;
          case "left":
            wrapperNode.style.marginLeft = "0";
            wrapperNode.style.marginRight = "auto";
            break;
        }

        if (linesInRange.length > 0) {
          const firstLine = linesInRange[0];
          firstLine.domNode.parentNode.insertBefore(
            wrapperNode,
            firstLine.domNode
          );
        }

        linesInRange.forEach((line, index) => {
          if (line.domNode) {
            Array.from(line.domNode.classList).forEach((className) => {
              if (
                className.startsWith("gradient-") ||
                className.startsWith("solid-")
              ) {
                line.domNode.classList.remove(className);
              }
            });

            const baseStyles = {
              background: selectedGradient,
              padding: "5px 15px",
              margin: "0",
              borderRadius: "0",
              display: "block",
              width: "100%",
            };

            if (index === 0) {
              Object.assign(baseStyles, {
                paddingTop: "10px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                marginTop: "10px",
              });
            }

            if (index === linesInRange.length - 1) {
              Object.assign(baseStyles, {
                paddingBottom: "10px",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                marginBottom: "10px",
              });
            }

            Object.assign(line.domNode.style, baseStyles);
            wrapperNode.appendChild(line.domNode);

            quill.update();
          }
        });
        quill.setSelection(range.index + range.length, 0);
      }
    }

    setShowGradientPicker(false);
  }, [selectedGradient, bgWidth, boxAlignment]);
  const removeGradient = useCallback(() => {
    const quill = editorRef.current.getEditor();
    let range = quill.getSelection();

    if (!range || range.length === 0) {
      const length = quill.getLength();
      quill.setSelection(0, length);
      range = { index: 0, length: length };
    }

    const [startBlock] = quill.getLine(range.index);
    const [endBlock] = quill.getLine(range.index + range.length);
    const startIndex = quill.getIndex(startBlock);
    const endIndex = quill.getIndex(endBlock) + endBlock.length();
    const linesInRange = quill.getLines(startIndex, endIndex - startIndex);

    quill.formatText(range.index, range.length, {
      gradient: false,
      gradientText: false,
    });

    linesInRange.forEach((line) => {
      if (line.domNode) {
        const wrapper = line.domNode.closest(".gradient-wrapper");
        if (wrapper) {
          const paragraphs = Array.from(wrapper.children);

          paragraphs.forEach((p) => {
            p.removeAttribute("style");
            p.className = p.className
              .split(" ")
              .filter(
                (cls) =>
                  !cls.startsWith("gradient-") &&
                  !cls.startsWith("solid-") &&
                  cls !== "custom-spacing"
              )
              .join(" ");
          });

          const parent = wrapper.parentNode;
          paragraphs.forEach((p) => {
            parent.insertBefore(p, wrapper);
          });
          wrapper.remove();
        } else {
          const lineIndex = quill.getIndex(line);
          quill.formatLine(lineIndex, 1, {
            "gradient-container": false,
            "gradient-wrapper": false,
            "gradient-wrapper-paste": false,
            gradientText: false,
            customParagraph: false,
            gradientLine: false,
            background: false,
            padding: false,
            borderRadius: false,
            margin: false,
            width: false,
          });

          const selectedText = quill.getText(range.index, range.length).trim();
          quill.root.querySelectorAll("*").forEach((element) => {
            if (element.textContent.includes(selectedText)) {
              const stylesToRemove = [
                "background",
                "padding",
                "margin",
                "box-decoration-break",
                "border-radius",
                "line-height",
              ];

              stylesToRemove.forEach((style) => {
                if (element.style[style]) {
                  element.style[style] = null;
                }
              });

              Array.from(element.children).forEach((child) => {
                stylesToRemove.forEach((style) => {
                  if (child.style[style]) {
                    child.style[style] = null;
                  }
                });
              });
            }
          });
          line.domNode.querySelectorAll("*").forEach((child) => {
            child.removeAttribute("style");
            child.className = child.className
              .split(" ")
              .filter(
                (cls) =>
                  !cls.startsWith("gradient-") &&
                  !cls.startsWith("solid-") &&
                  cls !== "custom-spacing"
              )
              .join(" ");
          });

          line.domNode.removeAttribute("style");
          console.log(line.domNode.className);
          line.domNode.className = line.domNode.className
            .split(" ")
            .filter(
              (cls) =>
                !cls.startsWith("gradient-") &&
                !cls.startsWith("solid-") &&
                cls !== "custom-spacing"
            )
            .join(" ");
        }
      }
    });
    setTimeout(() => {
      const docLength = quill.getLength();
      if (docLength > 0) {
        quill.setSelection(0, 0);
      }
    }, 0);

    setShowGradientPicker(false);
  }, [editorRef, setShowGradientPicker]);

  const colors = useMemo(
    () => [
      "#000000",
      "#e60000",
      "#ff9900",
      "#ffff00",
      "#008a00",
      "#0066cc",
      "#9933ff",
      "#ffffff",
      "#facccc",
      "#ffebcc",
      "#ffffcc",
      "#cce8cc",
      "#cce0f5",
      "#ebd6ff",
    ],
    []
  );
  const clearContent = useCallback((e) => {
    if (editorRef.current) {
      const quill = editorRef.current.getEditor();
      const delta = quill.clipboard.convert("");
      quill.setContents(delta, "user");
    }
  }, []);
  const handleEnter = useCallback(
    (range, context) => {
      const quill = editorRef.current.getEditor();
      const [line, offset] = quill.getLine(range.index);

      const gradientWrapper = line.domNode.closest(".gradient-wrapper");
      if (!gradientWrapper) {
        console.log("Kein gradient-wrapper gefunden, Standardverhalten.");
        return true;
      }

      console.log("Aktueller gradient-wrapper:", gradientWrapper);

      const nextWrapper = gradientWrapper.nextElementSibling;
      const isBetweenWrappers =
        nextWrapper && nextWrapper.classList.contains("gradient-wrapper");

      if (isBetweenWrappers) {
        console.log(
          "Zwischen zwei Wrappers, neue Zeile zwischen den Wrappern einfügen."
        );

        const newParagraph = document.createElement("p");
        newParagraph.innerHTML = "<br>";

        nextWrapper.parentNode.insertBefore(newParagraph, nextWrapper);

        quill.update();

        const newLineIndex = quill.getIndex(line) + line.length();

        quill.setSelection(newLineIndex, 0, "user");

        return false;
      }

      const lineIndex = quill.getIndex(line);
      const paragraphs = Array.from(gradientWrapper.querySelectorAll("p"));
      const currentLineIndex = paragraphs.indexOf(line.domNode);
      const isLastLine = currentLineIndex === paragraphs.length - 1;

      if (isLastLine) {
        console.log("Letzte Zeile im Wrapper, Standardverhalten.");
        const insertIndex = lineIndex + line.length();
        quill.insertText(insertIndex, "\n", "user");
        quill.setSelection(insertIndex + 1, 0, "user");
        return false;
      }

      // **In der Mitte des Wrappers**: Neue Zeile innerhalb des Wrappers einfügen
      const newParagraph = document.createElement("p");
      newParagraph.innerHTML = "<br>";

      newParagraph.style.borderRadius = "0px";
      newParagraph.style.margin = "0px";
      newParagraph.style.padding = "5px 15px";
      newParagraph.style.background = "rgb(239, 26, 26)";

      line.domNode.parentNode.insertBefore(
        newParagraph,
        line.domNode.nextSibling
      );

      quill.update();

      const newLineIndex = lineIndex + line.length();
      quill.setSelection(newLineIndex, 0, "user");

      return false;
    },
    [editorRef]
  );

  const handleBackspace = useCallback(
    (range, context) => {
      const quill = editorRef.current.getEditor();
      const [line, offset] = quill.getLine(range.index);

      if (offset === 0) {
        const [prevLine] = quill.getLine(range.index - 1);

        if (prevLine && prevLine.domNode.closest(".gradient-wrapper")) {
          const lineBlot = Quill.find(line.domNode);
          if (lineBlot) {
            lineBlot.remove();
          }

          const prevLineIndex = quill.getIndex(prevLine);
          const prevLineLength = prevLine.length();
          const newIndex = prevLineIndex + prevLineLength - 1;

          const docLength = quill.getLength();
          const safeIndex = Math.max(0, Math.min(newIndex, docLength - 1));

          quill.setSelection(safeIndex, 0, "user");

          return false;
        }
      }
      return true;
    },
    [editorRef]
  );

  const modules = useMemo(() => {
    return {
      keyboard: {
        bindings: {
          enter: {
            key: "enter",
            handler: handleEnter,
          },
          backspace: {
            key: "backspace",
            handler: handleBackspace,
          },
        },
      },
      toolbar: {
        container: [
          [{ size: Object.keys(sizes) }],
          ["bold", "italic", "underline", "strike"],
          [{ color: colors }],
          ["gradient", "removeGradient"],
          ["link", "image"],
          [{ align: [] }],
          ["clear"],
        ],
        handlers: {
          gradient: handleGradient,
          removeGradient: removeGradient,
          image: insertImageWithDefaultSize,
          clear: clearContent,
        },
      },
      clipboard: {
        matchers: [
          //["div", preserveDivBackground],
          // ['div[style*="width: fit-content"]', preserveGradientWrapper],
        ],
      },
      imageResize: { modules: ["Resize", "DisplaySize"] },
    };
  }, [
    handleGradient,
    removeGradient,
    insertImageWithDefaultSize,
    clearContent,
    colors,
  ]);

  const formats = useMemo(
    () => [
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "color",
      "background",
      "padding",
      "borderRadius",
      "margin",
      "link",
      "image",
      "align",
      "blockquote",
      "code-block",
      "gradient",
      "list",
      "bullet",
      "width",
      "height",
      "gradientBlock",
      "customParagraph",
      "gradientText",
      "gradientLine",
    ],
    []
  );

  useEffect(() => {
    if (editorRef.current && value && !initialized) {
      const quill = editorRef.current.getEditor();

      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = value;

      const gradientWrappers = tempDiv.querySelectorAll(".gradient-wrapper");

      if (gradientWrappers.length > 0) {
        let finalContent = "";

        Array.from(tempDiv.childNodes).forEach((node) => {
          if (node.classList && node.classList.contains("gradient-wrapper")) {
            const wrapperStyles = node.getAttribute("style") || "";
            const innerContent = node.innerHTML;

            finalContent += `<div class="gradient-wrapper" style="${wrapperStyles}">${innerContent}</div>`;
          } else {
            finalContent += node.outerHTML || node.textContent;
          }
        });

        quill.root.innerHTML = finalContent;
        quill.update();
      } else {
        quill.root.innerHTML = value;
        quill.update();
      }

      setInitialized(true);
    }
  }, [initialized]);

  const onEditorReady = useCallback(() => {
    if (editorRef.current) {
      const editor = editorRef.current.getEditor();
      editor.root.addEventListener(
        "DOMNodeInserted",
        (event) => {
          if (event.target.tagName === "P" && !event.target.textContent) {
            event.target.remove();
          }
        },
        { once: true }
      );
    }
  }, []);

  const isManualEnterRef = useRef(false);

  useEffect(() => {
    if (editorRef.current && !isManualEnterRef.current) {
      const quill = editorRef.current.getEditor();
      const currentContent = quill.root.innerHTML;

      if (currentContent !== value) {
        const lastChild = quill.root.lastChild;

        // Überprüfen, ob das letzte Element wirklich leer ist
        if (
          lastChild &&
          lastChild.tagName === "P" &&
          !lastChild.textContent.trim() &&
          !lastChild.querySelector(":not(br)") &&
          !lastChild.innerHTML.includes("<br>")
        ) {
          setTimeout(() => {
            const updatedLastChild = quill.root.lastChild;
            if (
              updatedLastChild &&
              updatedLastChild.tagName === "P" &&
              !updatedLastChild.textContent.trim() &&
              !updatedLastChild.querySelector(":not(br)") &&
              !updatedLastChild.innerHTML.includes("<br>")
            ) {
              updatedLastChild.remove();
              const newContent = quill.root.innerHTML;
              if (newContent !== value) {
                onChange(newContent);
              }
            }
          }, 500);
        }
      }
    }
  }, [value, onChange]);

  // const maxLength = 700;
  // useEffect(() => {
  //   const quill = editorRef.current.getEditor();
  //   const handler = () => {
  //     const text = quill.getText();
  //     if (text.length > maxLength + 1) {
  //       quill.deleteText(maxLength, quill.getLength());
  //     }
  //   };
  //   quill.on("text-change", handler);
  //   return () => {
  //     quill.off("text-change", handler);
  //   };
  // }, []);

  const maxImages = 5;
  const [imgValue, setImgValue] = useState("");
  useEffect(() => {
    const imgCount = (imgValue.match(/<img /g) || []).length;

    if (imgCount > maxImages) {
      alert(`Maximale Anzahl von ${maxImages} Bildern erreicht.`);

      const lastIndex = imgValue.lastIndexOf("<img");
      if (lastIndex !== -1) {
        setImgValue(imgValue.slice(0, lastIndex));
      }
    }
  }, [value]);
  return (
    <div
      {...quillattributes}
      style={{
        position: "relative",
      }}
    >
      <label style={{ display: "block", marginBottom: "8px" }}>{label}</label>
      <ReactQuill
        ref={editorRef}
        onChange={onChange}
        modules={modules}
        formats={formats}
        theme="snow"
        style={{ maxHeight: "300px", width: "100%" }}
        preserveWhitespace={false}
        onReady={onEditorReady}
        {...options}
      />
      <div>
        {/* <p>
          {maxLength - value.replace(/<[^>]+>/g, "").length} Zeichen verbleibend
        </p> */}
        <p>{`Bilder: ${
          (value.match(/<img /g) || []).length
        } / ${maxImages}`}</p>
      </div>

      {showGradientPicker && (
        <Draggable handle=".modal-header">
          <div
            className="gradient-picker-modal"
            style={{
              position: "absolute",
              top: "10px",
              left: "50px",
              zIndex: 1000,
              backgroundColor: "#fff",
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
              width: "280px", // Reduzierte Breite
            }}
          >
            <div
              className="modal-header"
              style={{
                cursor: "move",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                Gradient Picker
              </span>
              <span
                style={{
                  backgroundColor: "#007bff",
                  color: "#fff",
                  padding: "1px 6px",
                  borderRadius: "50%",
                  cursor: "pointer",
                  fontSize: "12px",
                }}
                onClick={() => setShowGradientPicker(false)}
              >
                ×
              </span>
            </div>

            {/* Kompakter Gradient Picker */}
            <div
              style={{
                transform: "scale(0.9)",
                transformOrigin: "top left",
                marginBottom: "-20px",
              }}
            >
              <GradientPicker
                value={selectedGradient}
                onChange={setSelectedGradient}
              />
            </div>

            {/* Historie */}
            {gradientHistory.length > 0 && (
              <div
                style={{
                  marginTop: "8px",
                  padding: "6px",
                  backgroundColor: "#f8f9fa",
                  borderRadius: "4px",
                  fontSize: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "4px",
                    flexWrap: "wrap",
                  }}
                >
                  <h1>Zuletzt verwendet</h1>
                  {gradientHistory.map((gradient, index) => (
                    <div
                      key={index}
                      onClick={() => setSelectedGradient(gradient)}
                      style={{
                        width: "24px",
                        height: "24px",
                        background: gradient,
                        borderRadius: "3px",
                        cursor: "pointer",
                        border:
                          selectedGradient === gradient
                            ? "2px solid #007bff"
                            : "1px solid #ddd",
                      }}
                      title={gradient}
                    />
                  ))}
                </div>
              </div>
            )}

            {/* Optionen */}
            <div
              style={{
                display: "flex",
                gap: "8px",
                marginTop: "8px",
                padding: "6px",
                backgroundColor: "#f8f9fa",
                borderRadius: "4px",
                fontSize: "12px",
              }}
            >
              <div style={{ flex: 1 }}>
                <div style={{ marginBottom: "4px", fontWeight: "500" }}>
                  Typ
                </div>
                <div style={{ display: "flex", gap: "4px" }}>
                  <button
                    style={{
                      padding: "3px 6px",
                      backgroundColor: bgWidth === "text" ? "#007bff" : "#fff",
                      color: bgWidth === "text" ? "#fff" : "#000",
                      border: "1px solid #007bff",
                      borderRadius: "3px",
                      cursor: "pointer",
                      fontSize: "12px",
                      flex: 1,
                    }}
                    onClick={() => setbgWidth("text")}
                  >
                    Text
                  </button>
                  <button
                    style={{
                      padding: "3px 6px",
                      backgroundColor: bgWidth === "box" ? "#007bff" : "#fff",
                      color: bgWidth === "box" ? "#fff" : "#000",
                      border: "1px solid #007bff",
                      borderRadius: "3px",
                      cursor: "pointer",
                      fontSize: "12px",
                      flex: 1,
                    }}
                    onClick={() => setbgWidth("box")}
                  >
                    Box
                  </button>
                </div>
              </div>

              {bgWidth === "text" && (
                <div style={{ flex: 1 }}>
                  <div style={{ marginBottom: "4px", fontWeight: "500" }}>
                    Ausrichtung
                  </div>
                  <div style={{ display: "flex", gap: "4px" }}>
                    {["left", "center", "right"].map((align) => (
                      <button
                        key={align}
                        style={{
                          padding: "3px 6px",
                          backgroundColor:
                            boxAlignment === align ? "#007bff" : "#fff",
                          color: boxAlignment === align ? "#fff" : "#000",
                          border: "1px solid #007bff",
                          borderRadius: "3px",
                          cursor: "pointer",
                          fontSize: "12px",
                          flex: 1,
                        }}
                        onClick={() => setBoxAlignment(align)}
                      >
                        {align === "left"
                          ? "L"
                          : align === "center"
                          ? "M"
                          : "R"}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Aktions-Buttons */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "8px",
                gap: "8px",
              }}
            >
              <button
                style={{
                  flex: 1,
                  backgroundColor: "#007bff",
                  color: "#fff",
                  padding: "4px 8px",
                  border: "none",
                  borderRadius: "3px",
                  cursor: "pointer",
                  fontSize: "12px",
                }}
                onClick={applyGradient}
              >
                Anwenden
              </button>
              <button
                style={{
                  flex: 1,
                  backgroundColor: "#dc3545",
                  color: "#fff",
                  padding: "4px 8px",
                  border: "none",
                  borderRadius: "3px",
                  cursor: "pointer",
                  fontSize: "12px",
                }}
                onClick={removeGradient}
              >
                Entfernen
              </button>
            </div>
          </div>
        </Draggable>
      )}
    </div>
  );
};

export default Editor;
