import React from "react";
import constructWhatsappLink from "../../../../utils/whatsappLink";

const WhatsappButton = ({
  number,
  whatsappText,
  text,
  className,
  activateWhatsApp,
  type,
  sendGoogleAnalytics,
}) => {
  let style = "";
  switch (type) {
    case "finance":
    case "individual":
      style = "rounded-full";
      break;
    case "stormgas":
      style = "rounded-0";
      break;
    case "property":
      style = "rounded-sm";
      break;
    case "basic":
      style = "rounded-full md:!text-sm";
      break;
  }
  return (
    activateWhatsApp && (
      <div className="flex justify-center">
        <a
          className={`${className}`}
          href={constructWhatsappLink(number, whatsappText)}
        >
          <button
            onClick={sendGoogleAnalytics}
            className={`
          ${style}
          gap-4 
          items-center 
          justify-center 
          bg-[#25D366] 
          font-semibold 

          text-white 
          flex 
          

          text-sm
          py-4
          px-7

          md:text-xl
          md:py-4
          md:px-10 

          mx-auto 

          shadow-[0px_0px_42px_0px_#25D366]`}
          >
            <img
              className="h-[24px] md:h-[25px]"
              src="/svg/whatsapp.svg"
              alt=""
            />
            {text}
          </button>
        </a>
      </div>
    )
  );
};

export default WhatsappButton;
