import React from "react";
import Slide from "./Slider";

const LocalReviews = ({
  localReviews,
  activateLocalReviews,
  type,
  logo,
  localReviewsHeader,
}) => {
  if (activateLocalReviews)
    return (
      <div
        className={`${type === "property" ? "bg-[#F1F3F6]" : "bg-[#fafafa]"}`}
      >
        <div className="md:text-primary text-[21px] primary:text-4xl text-center font-bold py-10 flex justify-center items-center gap-3 primary:flex-row primary:py-20">
          <p>{localReviewsHeader}</p>
          {logo && (
            <img
              src="/images/logo.png"
              alt="EinGuterTipp logo"
              className="h-6 primary:h-12"
            ></img>
          )}
        </div>

        <div className="hidden max-w-[1600px] m-auto w-screen !pt-0 sm:w-auto xl:grid-cols-3 sm:grid-cols-2 md:grid gap-7 p-7">
          {localReviews.map((e, i) => (
            <LocalReviewCard data={e} key={i} />
          ))}
        </div>

        <div className="pb-5 md:hidden">
          <Slide
            items={localReviews.map((e, i) => (
              <LocalReviewCard data={e} key={i} />
            ))}
          />
        </div>
      </div>
    );
};

const LocalReviewCard = ({ data }) => (
  <div className="flex flex-col gap-8 rounded-11xl p-7 sm:w-[400px] w-[300px] md:w-full md:mr-0 mr-4 bg-white">
    <div className="flex items-center gap-3 md:gap-6">
      <img
        className="object-cover w-16 rounded-full aspect-square md:w-20"
        src={
          data?.customerImage ||
          "/images/icons-8-mannlicher-benutzer-eingekreist-1.png"
        }
        alt="Profilbild"
      />
      <h1 className="md:text-[23px] text-base font-semibold">
        {data?.reviewedby}
      </h1>
    </div>
    <p
      className="text-[#868686] text-sm font-semibold break-words md:text-xl 
      h-[75px]
      md:h-[105px] 
      overflow-y-scroll 
      touch-pan-y
      "
    >
      {data?.comment}
    </p>
  </div>
);

export default LocalReviews;
